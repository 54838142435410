import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_13() {
  const [values, setvalues] = useState({
    soplodelcorazon: "",
    Dolordepechooeneltorax: "",
    Infartoenelcorazon: "",
    Algunaotraenfermedaddecorazon: "",
    Enfermedadesdecirculacion: "",
    Presionarterialalta: "",
    Bronquitiscronica: "",
    Asma: "",
    Tuberculosis: "",
    Ulceradelduodeno: "",
    Ulceradelestomago: "",
    Enfermedaddelhigado: "",
    Enfermedadesdelavesiculabiliar: "",
    Enfermedadesdelintestinocolonorecto: "",
    Enfermedadeselosrinones: "",
    Enfermedadesdelavejiga: "",
    Alguntumor: "",
    Paralisis: "",
    Perdidadeconocimientooconvulsiones: "",
    Astemiaadinamiayanorexia: "",
    Cuadrosdiarreicosfrecuentes: "",
    Hipertrofiaganglionar: "",
    Diaforisisnocturna: "",
    Enfermedadesdeloshuesos: "",
    Transtornosnerviososomentales: "",
    Algunaintervencionquirurgica: "",
    Algunaotraenfermedad: "",
    Describa: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Padece o a Padecido de</h4>
                    <hr></hr>             
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Algún soplo del corazón?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="soplodelcorazon"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      soplodelcorazon: e,
                    });
                  }}
                  value={values.soplodelcorazon}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Dolor de pecho o en el torax?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Dolordepechooeneltorax"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Dolordepechooeneltorax: e,
                    });
                  }}
                  value={values.Dolordepechooeneltorax}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Infarto en el corazón?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Infartoenelcorazon"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Infartoenelcorazon: e,
                    });
                  }}
                  value={values.Infartoenelcorazon}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Alguna otra enfermedad de corazón?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Algunaotraenfermedaddecorazon"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Algunaotraenfermedaddecorazon: e,
                    });
                  }}
                  value={values.Algunaotraenfermedaddecorazon}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Enfermedades de circulación?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdecirculacion"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadesdecirculacion: e,
                    });
                  }}
                  value={values.Enfermedadesdecirculacion}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Presión arterial alta?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Presionarterialalta"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Presionarterialalta: e,
                    });
                  }}
                  value={values.Presionarterialalta}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Bronquitis crónica?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Bronquitiscronica"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Bronquitiscronica: e,
                    });
                  }}
                  value={values.Bronquitiscronica}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Asma?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Asma"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Asma: e,
                    });
                  }}
                  value={values.Asma}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Tuberculosis?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Tuberculosis"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Tuberculosis: e,
                    });
                  }}
                  value={values.Tuberculosis}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Cáncer?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Cancer"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Cancer: e,
                    });
                  }}
                  value={values.Cancer}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Úlcera del duodeno?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Ulceradoduodeno"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Ulceradelduodeno: e,
                    });
                  }}
                  value={values.Ulceradelduodeno}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Úlcera del estómago?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Ulceradodeestomago"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Ulceradelestomago: e,
                    });
                  }}
                  value={values.Ulceradelestomago}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Enfermedad del hígado?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedaddelhigado"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedaddelhigado: e,
                    });
                  }}
                  value={values.Enfermedaddelhigado}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Enfermedades de la vesícula biliar ?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdelavesiculabiliar"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadesdelavesiculabiliar: e,
                    });
                  }}
                  value={values.Enfermedadesdelavesiculabiliar}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Enfermedades del intestino colon o recto{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdelintestinocolonorecto"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadesdelintestinocolonorecto: e,
                    });
                  }}
                  value={values.Enfermedadesdelintestinocolonorecto}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Enfermedades de los riñones?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdelrinones"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadeselosrinones: e,
                    });
                  }}
                  value={values.Enfermedadeselosrinones}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Enfermedades de la vejiga?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdelvejiga"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadesdelavejiga: e,
                    });
                  }}
                  value={values.Enfermedadesdelavejiga}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Diabetes?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Diabetes"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Diabetes: e,
                    });
                  }}
                  value={values.Diabetes}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Algún tumor? </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Alguntumor"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Alguntumor: e,
                    });
                  }}
                  value={values.Alguntumor}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Paralisis?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Paralisis"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Paralisis: e,
                    });
                  }}
                  value={values.Paralisis}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Perdida de conocimiento o convulsiones?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Perdidadeconocimientoconvulsiones"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Perdidadeconocimientooconvulsiones: e,
                    });
                  }}
                  value={values.Perdidadeconocimientooconvulsiones}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Astenia adinamia y anorexia
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Astemiaadinamiayanorexia"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Astemiaadinamiayanorexia: e,
                    });
                  }}
                  value={values.Astemiaadinamiayanorexia}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Cuadros diarreicos frecuentes?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Cuadrosdiarreicosfrecuentes"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Cuadrosdiarreicosfrecuentes: e,
                    });
                  }}
                  value={values.Cuadrosdiarreicosfrecuentes}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Hipertrofia ganglionar?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Hipertrofiaganglionar"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Hipertrofiaganglionar: e,
                    });
                  }}
                  value={values.Hipertrofiaganglionar}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Diaforesis nocturna?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Diaforisisnocturna"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Diaforisisnocturna: e,
                    });
                  }}
                  value={values.Diaforisisnocturna}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Enfermedades de los huesos?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enfermedadesdeloshuesos"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enfermedadesdeloshuesos: e,
                    });
                  }}
                  value={values.Enfermedadesdeloshuesos}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Transtornos nerviosos o mentales?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Transtornosnerviososmentales"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Transtornosnerviososomentales: e,
                    });
                  }}
                  value={values.Transtornosnerviososomentales}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Alguna intervención quirúrgica?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Algunaintervencionquirurgica"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Algunaintervencionquirurgica: e,
                    });
                  }}
                  value={values.Algunaintervencionquirurgica}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Alguna otra enfermedad?</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Algunaotraenfermedad"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Algunaotraenfermedad: e,
                    });
                  }}
                  value={values.Algunaotraenfermedad}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Describa en caso de poner Si
                </label>
                <textarea
                  className="form-control"
                  name="Describa"
                  value={values.Describa}
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Describa: e.target.value,
                    })
                  }
                >
                  {values.Describa}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
