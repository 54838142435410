const initial = {
  Nombre: "",
  ApellidoPaterno: "",
  ApellidoMaterno: "",
  CURP: "",
  RFC: "",
  Ocupacion: "",
  FechadeNacimiento: "",
  Sexo: "",
  LugardeResidencia: "",
  Cancer: "",
  Diabetes: "",
  EnfermedadesdelCorazon: "",
  EnfermedadesdelosRinones: "",
  EnfermedadesCerebrovasculares: "",
  Mentales: "",
  Suicidio: "",
  Otras: "",
  convidaelPadre: "",
  EdadPadre: "",
  EstadodeSaludPadre: "",
  EdadPadreasuMuerte: "",
  CausaMuertePadre: "",
  convidalaMadre: "",
  EdadMadre: "",
  EstadodeSaludMadre: "",
  EdadMadreasuMuerte: "",
  CausaMuerteMadre: "",
  NumerodeHermanosVivos: "",
  EstadodeSaludHermanos: "",
  NumerodeHermanosMuertos: "",
  CausaMuerteHermanos: "",
  convidaConyuge: "",
  EdadConyuge: "",
  EstadodeSaludConyuge: "",
  EdadConyugeasuMuerte: "",
  NumerodeHijosVivos: "",
  EstadodeSaludHijos: "",
  NumerodeHijosMuertos: "",
  CausadeMuerteHijos: "",
  FumaActualmente: "",
  Fumabaanteriormente: "",
  Enqueanoempezoafumar: "",
  Cigarrosdiariosquefumaofumaba: "",
  Enqueanodejodefumar: "",
  Porquerazon: "",
  IngiereBebidasAlcoholicas: "",
  Desdecuando: "",
  Clase: "",
  Frecuencia: "",
  Otros: "",
  Cantidadenmi: "",
  Llegaalestadodeembriaguez: "",
  Hatenidoaccidentesporelalcohol: "",
  Sidejodebebersenalefechaycausa: "",
  Cuantobebiaantesclasefrecuenciacantidad: "",
  Harecibidotratamientoporconsumodealcohol: "",
  Perteneceaalgunaasociaciondealcoholicos: "",
  Hautilizadodrogaspsicoactivas: "",
  Encasoafirmativoseñaletipodedroga: "",
  Encasoafirmativosenalefrecuencia: "",
  Encasoafirmativosenaleultimaocasion: "",
  Harecibidotratamientoporconsumodedrogas: "",
  Perteneceaalgunaasociaciondedrogadiccion: "",
  Havariadosupesoenelultimoano: "",
  Kgsaumentados: "",
  Kgsdisminuidos: "",
  Causa: "",
  Realizaregularmentealgunaactividadfisica: "",
  Tipodeejercicio: "",
  Padeceactualmentealgunaenfermedad: "",
  Enlosultimos2anoshaconsultadoalgunmedico: "",
  Estasujetoacualquiertratamientomedico: "",
  Harecibidotransfusionesdesangre: "",
  Haconsultadoporenfermedadtransmisionsexual: "",
  LehandichoquetieneSIDA: "",
  Harecibidotratamientolascausasanteriores: "",
  Encasodecualquierrespuestapositivadescriba1: "",
  tipodeSangre: "",
  soplodelcorazon: "",
  Dolordepechooeneltorax: "",
  Infartoenelcorazon: "",
  Algunaotraenfermedaddecorazon: "",
  Enfermedadesdecirculacion: "",
  Presionarterialalta: "",
  Bronquitiscronica: "",
  Asma: "",
  Tuberculosis: "",
  Ulceradelduodeno: "",
  Ulceradelestomago: "",
  Enfermedaddelhigado: "",
  Enfermedadesdelavesiculabiliar: "",
  Enfermedadesdelintestinocolonorecto: "",
  Enfermedadeselosrinones: "",
  Enfermedadesdelavejiga: "",
  Alguntumor: "",
  Paralisis: "",
  Perdidadeconocimientooconvulsiones: "",
  Astemiaadinamiayanorexia: "",
  Cuadrosdiarreicosfrecuentes: "",
  Hipertrofiaganglionar: "",
  Diaforisisnocturna: "",
  Enfermedadesdeloshuesos: "",
  Transtornosnerviososomentales: "",
  Algunaintervencionquirurgica: "",
  Algunaotraenfermedad: "",
  Describa: "",
  Electrocardiogramas: "",
  Analisisopruebasdelaboratorio: "",
  Radiografias: "",
  PruebaparadetectarSIDA: "",
  Haestadohospitalizadoalgunavez: "",
  SitioyFecha: "",
  Encasodecualquierrespuestaafirmativadescriba: "",
  Endermedadesdeglandulasmamarias: "",
  EnfermedadesdeUtero: "",
  Estaembarazada: "",
  Semanasdegestacionencasoafirmativo: "",
  Fechadeultimaregla: "",
  AntecedentesginecoobstetricosGESTA: "",
  AntecedentesginecoobstetricosPARA: "",
  AntecedentesginecoobstetricosABORTOS: "",
  AntecedentesginecoobstetricosCESAREA: "",
  Selehapracticadomastografia: "",
  MotivosyResultados: "",
  SelehapracticadoPapanicolau: "",
  FechaPapanicolau: "",
  Resultado: "",
  Estaturaencms: "",
  PesoenKgs: "",
  Lomidio: "",
  Lopeso: "",
  IndicedeMasaCorporal: "",
  GlucosaCapliar: "",
  PerimetroenToraxInspiracionPlena: "",
  PerimetroenToraxespiracionforzada: "",
  Perimetrodelabdomenanivelombligo: "",
  Pulsofrecuenciacardiacaenminutocompleto: "",
  Esritmico: "",
  Numerodeextrasistolesporminuto: "",
  PruebadeEsfuerzoFrecuenciaenminutocompleto: "",
  PruebadeEsfuerzoEsritmico: "",
  PruebadeEsfuerzoPruebadeextrasoidespormin: "",
  PresionArterialMetodoAuscultatorio: "",
  PresionArterialdiastólica: "",
  Nivelespaciointercostalenquepalpaelcorazon: "",
  Existealgundatoanormaldepalpitacion: "",
  Laauscultacionenfocosclasicosesnormal: "",
  CrecimientoCardiaco: "",
  Disnea: "",
  Edema: "",
  Constante: "",
  Inconstante: "",
  Transmitido: "",
  Localizado: "",
  Sistolico: "",
  Presistolico: "",
  Diastolico: "",
  Suavegr12: "",
  Moderadogr34: "",
  Fuertegr56: "",
  Despuesdelejercicioseincrementa: "",
  Despuesdelejerciciosedesaparece: "",
  Despuesdelejercicionocambia: "",
  Despuesdelejerciciosedisminuye: "",
  ImpresionDiagnostica: "",
  Ausencia: "",
  Protesis: "",
  Amalgama: "",
  Nombreytelefonodelodontologo: "",
  Anormalidadensuaspecto: "",
  ExisteDisnea: "",
  ExisteDeformidad: "",
  Faltaalgunmiembroopartedeel: "",
  HayParalsiisoParesias: "",
  Movimientosanormales: "",
  Lamanchaesnormal: "",
  Seapreciatranstornopsiquico: "",
  Existecualquieranormalidad: "",
  Enestadoanatomicodeojos: "",
  Envisiondeojos: "",
  Enaudiciondecadaoido: "",
  Encavidadbucalylafaringe: "",
  Enelcuello: "",
  Entorax: "",
  Encolumnavertebral: "",
  Enabdomen: "",
  Enlasextremidades: "",
  Existealgundatoquesenalequefume: "",
  Conoceenelexaminadoalgunfactorenhabitos: "",
  ConsideraelestadodesaludcomoNormal: "",
  ConsideraelestadodesaludcomoDudoso: "",
  ConsideraelestadodesaludcomoAnormal: "",
  Recogiopersonalmentemuestradeorina: "",
  DatosAdicionales: "",
  NombredelMedico: "",
  DomiciliodelMedico: "",
  TelefonodelMedico: "",
  Ciudad: "",
  Fecha: "",
  Solicitanteseidentificoconlacredencial: "",
  SolicitanteautorizaIMacasaproductora: "",
  Expedidopor: "",
  Numero: "",
  Vigencia: "",
  ClaveyNombredelAgente: "",
  Miconsultorio: "",
  Sudomicilo: "",
  Otrolugar: "",
  Especificarotrolugar: "",
  NombreCompletodelExaminador: "",
  TelefonodelExaminador: "",
  DomiciliodelExaminador: "",
  CedulaProfesionaldelExaminador: "",
};

const options = [
  { value: "Femenino", label: "Femenino" },
  { value: "Masculino", label: "Masculino" },
];
const opciones = [
  { value: "Si", label: "Si" },
  { value: "No", label: "No" },
];
const opcionesFrecuencia = [
  { value: "Diaria", label: "Diaria" },
  { value: "Semanal", label: "Semanal" },
  { value: "Quincenal", label: "Quincenal" },
  { value: "Mensual", label: "Mensual" },
  { value: "Otros", label: "Otros" },
];
const tipodeSangre = [
  { value: "A positivo (A +)", label: "A positivo (A +)" },
  { value: "A negativo (A-)", label: "A negativo (A-)" },
  { value: "B positivo (B +)", label: "B positivo (B +)" },
  { value: "B negativo (B-)", label: "B negativo (B-)" },
  { value: "AB positivo (AB+)", label: "AB positivo (AB+)" },
  { value: "AB negativo (AB-)", label: "AB negativo (AB-)" },
  { value: "O positivo (O+)", label: "O positivo (O+)" },
  { value: "O negativo (O-)", label: "O negativo (O-)" },
];

const deportes = [
  { value: "Carreras de autos", label: "Carreras de autos" },
  { value: "actividades encuestres", label: "actividades encuestres" },
  { value: "buceo", label: "buceo" },
  { value: "alpinismo", label: "alpinismo" },
  { value: "vuelo sin motor/ aviación", label: "vuelo sin motor/ aviación" },
  { value: "motociclismo/carreras", label: "motociclismo/carreras" },
  { value: "paracaidismo", label: "paracaidismo" },
];

const puesto = [
  { value: "Actor principal", label: "Actor principal" },
  { value: "Coestelar", label: "Coestelar" },
  { value: "Productor ejecutivo", label: "Productor ejecutivo" },
  { value: "Co productor", label: "Co productor" },
  { value: "Cameo", label: "Cameo" },
  { value: "Productor", label: "Productor" },
  { value: "Director", label: "Director" },
  { value: "Escritor", label: "Escritor" },
  { value: "Dirección de fotografía", label: "Dirección de fotografía" },
  { value: "Actor protagónico", label: "Actor protagónico" },
  { value: "Otro", label: "Otro" },
];

export {
  options,
  opciones,
  opcionesFrecuencia,
  tipodeSangre,
  initial,
  deportes,
  puesto,
};
