import { useState } from "react";
import Select from "react-select";
import { options } from "../../utils/initialParams";

const styleTitle = {
  color: '#596875'
};



export default function Form_1() {
  const [values, setvalues] = useState({
    Nombre: "",
    ApellidoPaterno: "",
    ApellidoMaterno: "",
    CURP: "",
    RFC: "",
    Ocupacion: "",
    FechadeNacimiento: "",
    Sexo: "",
    LugardeResidencia: "",
  });

  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 class="Datos-Generales" style={styleTitle}>Datos Generales</h4>
            <hr></hr>            
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Nombre}
                  name="Nombre"
                  onChange={(e) =>
                    setvalues({ ...values, Nombre: e.target.value })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Apelido Paterno</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.ApellidoPaterno}
                  name="ApellidoPaterno"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ApellidoPaterno: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Apelido Materno</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.ApellidoMaterno}
                  name="ApellidoMaterno"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ApellidoMaterno: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">CURP</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.CURP}
                  name="CURP"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CURP: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">RFC</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.RFC}
                  name="RFC"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      RFC: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Ocupación Actual</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Ocupacion}
                  name="Ocupacion"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Ocupacion: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fecha de Nacimiento</label>
                <input
                  type="date"
                  className="form-control"
                  value={values.FechadeNacimiento}
                  name="FechadeNacimiento"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      FechadeNacimiento: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-outline col-lg-4">
                <label className="form-label">Sexo</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={options}
                  onChange={(e) => setvalues({ ...values, Sexo: e })}
                  value={values.Sexo}
                  name="Sexo"
                />
              </div>

              <div className="form-outline col-lg-4">
                <label className="form-label">Lugar de Residencia</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.LugardeResidencia}
                  name="LugardeResidencia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      LugardeResidencia: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
