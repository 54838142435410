import React from "react";

export default function Navbar({ closeSession }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img className="img-fluid" src="logo-login.png" alt="" />
        </a>
        <div className="collapse navbar-collapse" id="navbarColor01"></div>
        <div className="d-flex col-lg-2 col-12 justify-content-end text-center">
          <button
            className="btn btn-primary my-2 my-sm-0"
            type="button"
            onClick={closeSession}
          >
            Cerrar Sesión
          </button>
        </div>
      </div>
    </nav>
  );
}
