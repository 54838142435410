import { useState } from "react";
import Select from "react-select";
import { options } from "../../utils/initialParams";

const styleTitle = {
  color: '#596875'
};



export default function Form_1() {
  const [values, setvalues] = useState({
    NombredelProyecto: "",
    NombredelaProduccion: ""
  });

  return (
    <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-md-8 col-lg-8 col-xl-12">
        <div className="card mb-3 mt-4 p-4 rounded">
        <h4 class="Datos-Generales" style={styleTitle}>Información del Proyecto</h4>
        <hr></hr>
          <div className="row">
            <div className="form-outline col-lg-4">
              <label className="form-label">Nombre del Proyecto</label>
              <input
                type="text"
                className="form-control"
                value={values.NombredelProyecto}
                onChange={(e) =>
                    setvalues({
                      ...values,
                      NombredelProyecto: e.target.value,
                    })
                  }
              />
            </div>
            <div className="form-outline col-lg-4">
              <label className="form-label">Nombre de Casa Productora</label>
              <input
                type="text"
                className="form-control"
                value={values.NombredelaProduccion}
                onChange={(e) =>
                    setvalues({
                      ...values,
                      NombredelaProduccion: e.target.value,
                    })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
  );
}
