import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_16() {
  const [values, setvalues] = useState({
    Estaturaencms: "",
    PesoenKgs: "",
    Lomidio: "",
    Lopeso: "",
    IndicedeMasaCorporal: "",
    GlucosaCapliar: "",
    PerimetroenToraxInspiracionPlena: "",
    PerimetroenToraxespiracionforzada: "",
    Perimetrodelabdomenanivelombligo: "",
    Pulsofrecuenciacardiacaenminutocompleto: "",
    Esritmico: "",
    Numerodeextrasistolesporminuto: "",
    PruebadeEsfuerzoFrecuenciaenminutocompleto: "",
    PruebadeEsfuerzoEsritmico: "",
    PruebadeEsfuerzoPruebadeextrasoidespormin: "",
    PresionArterialMetodoAuscultatorio: "",
    PresionArterialdiastólica: "",
    Nivelespaciointercostalenquepalpaelcorazon: "",
    Existealgundatoanormaldepalpitacion: "",
    Laauscultacionenfocosclasicosesnormal: "",
    CrecimientoCardiaco: "",
    Disnea: "",
    Edema: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Exploración Física</h4>
                    <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Estatura en cms.</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Estaturaencms}
                  name="Estaturaencms"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Estaturaencms: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Peso en Kgs</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PesoenKgs}
                  name="PesoenKgs"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PesoenKgs: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Lomidio}
                  name="Lomidio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Lomidio: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;¿Lo midió?</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Lopeso}
                  name="Lopeso"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Lopeso : e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;¿Lo pesó?</label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Indice de Mása Corporal</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.IndicedeMasaCorporal}
                  name="IndicedeMasaCorporal"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      IndicedeMasaCorporal: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Glucosa Capilar</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.GlucosaCapliar}
                  name="GlucosaCapliar"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      GlucosaCapliar: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Perímetro en Torax Inspiración Plena
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PerimetroenToraxInspiracionPlena}
                  name="PerimetroenToraxInspiracionPlena"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PerimetroenToraxInspiracionPlena: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Perímetro en Torax espiración forzada
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PerimetroenToraxespiracionforzada}
                  name="PerimetroenToraxespiracionforzada"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PerimetroenToraxespiracionforzada: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Perímetro del abdomen a nivel ombligo
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Perimetrodelabdomenanivelombligo}
                  name="Perimetrodelabdomenanivelombligo"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Perimetrodelabdomenanivelombligo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Pulso frecuencia cardiaca en minuto completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Pulsofrecuenciacardiacaenminutocompleto}
                  name="Pulsofrecuenciacardiacaenminutocompleto"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Pulsofrecuenciacardiacaenminutocompleto: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Es rítmico</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Esritmico}
                  name="Esritmico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Esritmico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Número de extrasístoles por minuto
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Numerodeextrasistolesporminuto}
                  name="Numerodeextrasistolesporminuto"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Numerodeextrasistolesporminuto: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Prueba de Esfuerzo - Frecuencia en minuto completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PruebadeEsfuerzoFrecuenciaenminutocompleto}
                  name="PruebadeEsfuerzoFrecuenciaenminutocompleto"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PruebadeEsfuerzoFrecuenciaenminutocompleto: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Prueba de Esfuerzo - Es rítmico
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PruebadeEsfuerzoEsritmico}
                  name="PruebadeEsfuerzoEsritmico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PruebadeEsfuerzoEsritmico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Prueba de Esfuerzo - Prueba de extrasoides por min
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PruebadeEsfuerzoPruebadeextrasoidespormin}
                  name="PruebadeEsfuerzoPruebadeextrasoidespormin"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PruebadeEsfuerzoPruebadeextrasoidespormin: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Presión Arterial - Método Auscultatorio{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PresionArterialMetodoAuscultatorio}
                  name="PresionArterialMetodoAuscultatorio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PresionArterialMetodoAuscultatorio: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Presión Arterial - diastólica
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.PresionArterialdiastólica}
                  name="PresionArterialdiastólica"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PresionArterialdiastólica: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Nivel espacio intercostal en que palpa el corazón
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Nivelespaxiointercostalenquepalpaelcorazon}
                  name="Nivelespaxiointercostalenquepalpaelcorazon"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Nivelespaxiointercostalenquepalpaelcorazon: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Existe algún dato anormal de palpitación?{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Existealgundatoanormaldepalpitacion}
                  name="Existealgundatoanormaldepalpitacion"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Existealgundatoanormaldepalpitacion: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿La auscultación en focos clásicos es normal?
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Laauscultacionenfocosclasicosesnormal}
                  name="Laauscultacionenfocosclasicosesnormal"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Laauscultacionenfocosclasicosesnormal: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.CrecimientoCardiaco}
                  name="CrecimientoCardiaco"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CrecimientoCardiaco: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Crecimiento Cardiaco
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Disnea}
                  name="Disnea"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Disnea: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Disnea</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Edema}
                  name="Edema"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Edema: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Edema</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
