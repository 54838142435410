import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_18() {
  const [values, setvalues] = useState({
    Ausencia: "",
    Protesis: "",
    Amalgama: "",
    Nombreytelefonodelodontologo: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Odontograma</h4>
                    <hr></hr>   
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Ausencia</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Ausencia}
                  name="Ausencia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Ausencia: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Prótesis </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Prótesis}
                  name="Prótesis"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Prótesis: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Amalgama </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Amalgama}
                  name="Amalgama"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Amalgama: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Nombre y teléfono del odontólogo
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Nombreytelefonodelodontologo}
                  name="Nombreytelefonodelodontologo"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Nombreytelefonodelodontologo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
