/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useContacto from "../../hooks/useContacto";
import useLogin from "../../hooks/useLogin";
import Swal from "sweetalert2";
import InformacionProyecto from "../../components/Form/InformacionProyecto";
import DatosGenerales from "../../components/Form/DatosGenerales";
import AntecedentesPatologicosFamiliares from "../../components/Form/AntecedentesPatologicosFamiliares";
import HistoriaFamiliarPadre from "../../components/Form/HistoriaFamiliarPadre";
import HistorialFamiliarMadre from "../../components/Form/HistoriaFamiliarMadre";
import HistoriaFamiliarHermanos from "../../components/Form/HistoriaFamiliarHermanos";
import HistoriaFamiliarConyuge from "../../components/Form/HistoriaFamiliarConyuge";
import HistoriaFamiliarHijos from "../../components/Form/HistoriaFamiliarHijos";
import HabitosTabaco from "../../components/Form/HabitosTabaco";
import HabitosAlcohol from "../../components/Form/HabitosAlcohol";
import HabitosDrogas from "../../components/Form/HabitosDrogas";
import HabitosALimenticiosDeportes from "../../components/Form/HabitosALimenticiosDeportes";
import AntecedentesPersonalesPadecimientoActual from "../../components/Form/AntecedentesPersonalesPadecimientoActual";
import PadeceOaPadecido from "../../components/Form/PadeceOaPadecido";
import AlgunaVezPracticado from "../../components/Form/AlgunaVezPracticado";
import MujerPadeceOaPadecido from "../../components/Form/MujerPadeceOaPadecido";
import ExploracionFisica from "../../components/Form/ExploracionFisica";
import PresenciaSoploCardiaco from "../../components/Form/PresenciaSoploCardiaco";
import Odontograma from "../../components/Form/Odontograma";
import AnteCualquierRespuesta from "../../components/Form/AnteCualquierRespuesta";
import DatosAdicionales from "../../components/Form/DatosAdicionales";
import MedicoAcostumbra from "../../components/Form/MedicoAcostumbra";
import InformacionCertificado from "../../components/Form/InformacionCertificado";
import PractidadoEn from "../../components/Form/PractidadoEn";
import Examinador from "../../components/Form/Examinador";
import ButtonSubmit from "../../components/UI/ButtonSubmit";
import Navbar from "../../components/UI/Navbar";
import { verification_typeof, getValores } from "../../utils/getInformacion";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const navigate = useNavigate();
  const { error, contacto, message, success } = useContacto();
  const { logout } = useLogin();
  const [internetValidato, setinternetValidato] = useState(false);
  const [total, settotal] = useState(0);
  const [tipo, settipo] = useState(0);

  const closeSession = () => {
    logout();
  };

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const getInformacion = () => {
    const valores = localStorage.getItem("informacion_cuestionario_clinica");

    if (valores) {
      settotal(JSON.parse(valores).length);
    } else {
      settotal(0);
    }
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        title: "Certificado médico",
        text: "Certificado guardado con exito",
        icon: "success",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Imprimir",
        cancelButtonText: "Firmar digitalmente",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(
            "https://certificado.lcicorporativo.co/api/v1/vtiger_certificado/pdf?id=" +
              message,
            "_self"
          );
        } else {
          window.open(
            "https://certificado.lcicorporativo.co/edit-pdf?id=" + message,
            "_self"
          );
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [error, message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    settipo(!internetValidato ? 0 : 1)
    
    const formValues = {};
    for (const input of e.target.elements) {
      if (input.name === "") {
        continue;
      }

      if (input.type === "checkbox") {
        formValues[input.name] = verification_typeof(input.checked);
      } else {
        formValues[input.name] = verification_typeof(input.value);
      }
    }
    enviar(formValues);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const validadorInternet = await fetch(
        "https://jsonplaceholder.typicode.com/todos/1"
      )
        .then((response) => response.json())
        .then((json) => true)
        .catch(({ message }) => {
          if (message === "Failed to fetch") {
            return false;
          }
        });

      setinternetValidato(validadorInternet);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const enviar = (values) => {
    if (tipo === 0) {
      const historial = localStorage.getItem(
        "informacion_cuestionario_clinica"
      );
      if (historial) {
        const historialArray = JSON.parse(historial);
        const valores = getValores(values);
        historialArray.push(valores);
        localStorage.setItem(
          "informacion_cuestionario_clinica",
          JSON.stringify(historialArray)
        );

        getInformacion();
      } else {
        const historialArray = [];
        const valores = getValores(values);
        historialArray.push(valores);
        localStorage.setItem(
          "informacion_cuestionario_clinica",
          JSON.stringify(historialArray)
        );

        getInformacion();
      }
    } else {
      const historial = localStorage.getItem(
        "informacion_cuestionario_clinica"
      );

      if (historial) {
        const historialArray = JSON.parse(historial);
        const valores = getValores(values);
        historialArray.push(valores);
        Promise.all(
          historialArray
            .map((item) =>
              contacto(item).then((response) => {
                return response;
              })
            )
            .then(() => {
              localStorage.removeItem("informacion_cuestionario_clinica");
              Swal.close();
              Swal.fire({
                title: "Exito",
                text: "Formulario guardado con exito",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/");
                }
              });
            })
        );
      } else {
        Swal.fire({
          title: "Espere",
          text: "Guardando Formulario",
          imageUrl: "logo-login.png",
          imageWidth: 500,
          imageHeight: 100,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal-loader",
          },
        });

        const valores = getValores(values);
        contacto(valores);
      }
    }
  };

  const SendInformacion = async () => {
    const historial = localStorage.getItem("informacion_cuestionario_clinica");
    Swal.fire({
      title: "Espere",
      text: "Guardando Formulario",
      imageUrl: "logo-login.png",
      imageWidth: 500,
      imageHeight: 100,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal-loader",
      },
    });
    if (historial) {
      const validadorInternet = await fetch(
        "https://jsonplaceholder.typicode.com/todos/1"
      )
        .then((response) => response.json())
        .then((json) => true)
        .catch(({ message }) => {
          if (message === "Failed to fetch") {
            return false;
          }
        });

      if (validadorInternet) {
        const historialArray = JSON.parse(historial);
        Promise.all(historialArray.map((item) => contacto(item))).then(() => {
          localStorage.removeItem("informacion_cuestionario");
          Swal.close();
          Swal.fire({
            title: "Exito",
            text: "Formulario guardado con exito",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "No hay conexión a internet",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        title: "No hay información",
        text: "No hay formularios guardamos localmente",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Navbar closeSession={closeSession}></Navbar>
        <section className="vh-100 pb-5">
          <div className="col-md-12 col-lg-12 col-xl-12 col-12 mt-3 text-center">
            {total !== "" && (
              <div className="alert alert-success text-center" role="alert">
                <strong>
                  <i className="fas fa-check-circle"></i>
                </strong>
                <span>
                  Tienes un total de {total} prospectos guardados localmente
                </span>
                <br />
                <button
                  className="btn btn-danger mx-auto"
                  onClick={SendInformacion}
                  type="button"
                >
                  Enviar formularios guardados localmente
                </button>
              </div>
            )}
          </div>
          <InformacionProyecto />
          <DatosGenerales />
          <AntecedentesPatologicosFamiliares />
          <HistoriaFamiliarPadre />
          <HistorialFamiliarMadre />
          <HistoriaFamiliarHermanos />
          <HistoriaFamiliarConyuge />
          <HistoriaFamiliarHijos />
          <HabitosTabaco />
          <HabitosAlcohol />
          <HabitosDrogas />
          <HabitosALimenticiosDeportes />
          <AntecedentesPersonalesPadecimientoActual />
          <PadeceOaPadecido />
          <AlgunaVezPracticado />
          <MujerPadeceOaPadecido />
          <ExploracionFisica />
          <PresenciaSoploCardiaco />
          <Odontograma />
          <AnteCualquierRespuesta />
          <DatosAdicionales />
          <MedicoAcostumbra />
          <InformacionCertificado />
          <PractidadoEn />
          <Examinador />
          <ButtonSubmit
            handleSubmit={handleSubmit}
            internetValidato={internetValidato}
            settipo={settipo}
          />
        </section>
      </form>
    </>
  );
}
