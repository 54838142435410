import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_19() {
  const [values, setvalues] = useState({
    Anormalidadensuaspecto: "",
    ExisteDisnea: "",
    ExisteDeformidad: "",
    Faltaalgunmiembroopartedeel: "",
    HayParalsiisoParesias: "",
    Movimientosanormales: "",
    Lamanchaesnormal: "",
    Seapreciatranstornopsiquico: "",
    Existecualquieranormalidad: "",
    Enestadoanatomicodeojos: "",
    Envisiondeojos: "",
    Enaudiciondecadaoido: "",
    Encavidadbucalylafaringe: "",
    Enelcuello: "",
    Entorax: "",
    Encolumnavertebral: "",
    Enabdomen: "",
    Enlasextremidades: "",
    Existealgundatoquesenalequefume: "",
    Conoceenelexaminadoalgunfactorenhabitos: "",
    ConsideraelestadodesaludcomoNormal: "",
    ConsideraelestadodesaludcomoDudoso: "",
    ConsideraelestadodesaludcomoAnormal: "",
    Recogiopersonalmentemuestradeorina: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Ante cualquier respuesta afirmativa dar información detallada</h4>
                    <hr></hr>   
            <div className="row">
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Anormalidadensuaspecto}
                  name="Anormalidadensuaspecto"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Anormalidad en su aspecto
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.ExisteDisnea}
                  name="ExisteDisnea"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Existe Disnea</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.ExisteDeformidad}
                  name="ExisteDeformidad"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Existe Deformidad
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Faltaalgunmiembroopartedeel}
                  name="Faltaalgunmiembroopartedeel"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Falta algún miembro o parte de él
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.HayParalsiisoParesias}
                  name="HayParalsiisoParesias"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Hay Parálsiis o Paresias
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Movimientosanormales}
                  name="Movimientosanormales"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Movimientos anormales
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Lamanchaesnormal}
                  name="Lamanchaesnormal"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;La mancha es normal
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Seapreciatranstornopsiquico}
                  name="Seapreciatranstornopsiquico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Se aprecia transtorno psiquico
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Existecualquieranormalidad}
                  name="Existecualquieranormalidad"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Existe cualquier anormalidad
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Enestadoanatomicodeojos}
                  name="Enestadoanatomicodeojos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En estado anatómico de ojos
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Envisiondeojos}
                  name="Envisiondeojos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En visión de ojos
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Enaudiciondecadaoido}
                  name="Enaudiciondecadaoido"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En audición de cada oido
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Encavidadbucalylafaringe}
                  name="Encavidadbucalylafaringe"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En cavidad bucal y la faringe
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Enelcuello}
                  name="Enelcuello"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En el cuello - Ganglios - tiroides - Turgencia - e{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Entorax}
                  name="Entorax"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En torax - inspección - auscultación{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Encolumnavertebral}
                  name="Encolumnavertebral"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En columna vertebral - Deforidad - dolor - etc
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Enabdomen}
                  name="Enabdomen"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En abdomen - inspección - palpación - etc{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Enlasextremidades}
                  name="Enlasextremidades"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;En las extremidades - varices - ulceras - etc{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Existealgundatoquesenalequefume}
                  name="Existealgundatoquesenalequefume"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;¿Existe algún dato que señale que fume?{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Conoceenelexaminadoalgunfactorenhabitos}
                  name="Conoceenelexaminadoalgunfactorenhabitos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp; ¿Conoce en el examinado algún factor en hábitos?{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.ConsideraelestadodesaludcomoNormal}
                  name="ConsideraelestadodesaludcomoNormal"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp; Considera el estado de salud como Normal{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.ConsideraelestadodesaludcomoDudoso}
                  name="ConsideraelestadodesaludcomoDudoso"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp; Considera el estado de salud como Dudoso
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.ConsideraelestadodesaludcomoAnormal}
                  name="ConsideraelestadodesaludcomoAnormal"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp; Considera el estado de salud como Anormal{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Recogiopersonalmentemuestradeorina}
                  name="Recogiopersonalmentemuestradeorina"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      convidalaMadre: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp; ¿Recogió personalmente muestra de orina?{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
