import { useState } from "react";
import Select from "react-select";
import { opciones, tipodeSangre } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_12() {
  const [values, setvalues] = useState({
    Padeceactualmentealgunaenfermedad: "",
    Enlosultimos2anoshaconsultadoalgunmedico: "",
    Estasujetoacualquiertratamientomedico: "",
    Harecibidotransfusionesdesangre: "",
    Haconsultadoporenfermedadtransmisionsexual: "",
    LehandichoquetieneSIDA: "",
    Harecibidotratamientolascausasanteriores: "",
    Encasodecualquierrespuestapositivadescriba1: "",
    tipodeSangre: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Antecedentes Personales y Padecimiento Actual</h4>
                    <hr></hr>             
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Padece actualmente alguna enfermedad?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Padeceactualmentealgunaenfermedad"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Padeceactualmentealgunaenfermedad: e,
                    });
                  }}
                  value={values.Padeceactualmentealgunaenfermedad}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿En los últimos 2 años ha consultado algún médico?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Enlosultimos2anoshaconsultadoalgunmedico"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Enlosultimos2anoshaconsultadoalgunmedico: e,
                    });
                  }}
                  value={values.Enlosultimos2anoshaconsultadoalgunmedico}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Está sujeto a cualquier tratamiento médico?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Estasujetoacualquiertratamientomedico"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Estasujetoacualquiertratamientomedico: e,
                    });
                  }}
                  value={values.Estasujetoacualquiertratamientomedico}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha recibido transfusiones de sangre?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Harecibidotransfusionesdesangre"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Harecibidotransfusionesdesangre: e,
                    });
                  }}
                  value={values.Harecibidotransfusionesdesangre}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha consultado por enfermedad transmisión sexual?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Haconsultadoporenfermedadtransmisionsexual"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Haconsultadoporenfermedadtransmisionsexual: e,
                    });
                  }}
                  value={values.Haconsultadoporenfermedadtransmisionsexual}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Le han dicho que tiene SIDA?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="LehandichoquetieneSIDA"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      LehandichoquetieneSIDA: e,
                    });
                  }}
                  value={values.LehandichoquetieneSIDA}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha recibido tratamiento las causas anteriores?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Harecibidotratamientolascausasanteriores"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Harecibidotratamientolascausasanteriores: e,
                    });
                  }}
                  value={values.Harecibidotratamientolascausasanteriores}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  En caso de cualquier respuesta positiva describa{" "}
                </label>
                <textarea
                  className="form-control"
                  name="Encasoquierencualquierrespuestapositiva"
                  value={values.Encasoquierencualquierrespuestapositiva}
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Encasoquierencualquierrespuestapositiva: e.target.value,
                    })
                  }
                >
                  {values.Encasoquierencualquierrespuestapositiva}
                </textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Tipo de sangre</label>
                <Select
                  placeholder="Seleccione una opción"
                  options={tipodeSangre}
                  name="tipodeSangre"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      tipodeSangre: e,
                    });
                  }}
                  value={values.tipodeSangre}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
