import { useState } from "react";
const styleTitle = {
  color: "#596875",
};
export default function Form_15() {
  const [values, setvalues] = useState({
    Endermedadesdeglandulasmamarias: "",
    EnfermedadesdeUtero: "",
    Estaembarazada: "",
    Semanasdegestacionencasoafirmativo: "",
    Fechadeultimaregla: "",
    AntecedentesginecoobstetricosGESTA: "",
    AntecedentesginecoobstetricosPARA: "",
    AntecedentesginecoobstetricosABORTOS: "",
    AntecedentesginecoobstetricosCESAREA: "",
    Selehapracticadomastografia: "",
    MotivosyResultados: "",
    SelehapracticadoPapanicolau: "",
    FechaPapanicolau: "",
    Resultado: "",
    FechaMastrografia: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
            <h4 style={styleTitle}>
              En caso de ser mujer padece o a padecido de
            </h4>
            <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Endermedadesdeglandulasmamarias}
                  name="Endermedadesdeglandulasmamarias"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Endermedadesdeglandulasmamarias: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Endermedades de glandulas mamarias
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.EnfermedadesdeUtero}
                  name="EnfermedadesdeUtero"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EnfermedadesdeUtero: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Enfermedades de Utero
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Estaembarazada}
                  name="Estaembarazada"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Estaembarazada: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Está embarazada
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Semanas de gestación en caso afirmativo
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Semanasdegestacionencasoafirmativo}
                  name="Semanasdegestacionencasoafirmativo"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Semanasdegestacionencasoafirmativo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fecha de última regla</label>
                <input
                  type="date"
                  className="form-control"
                  value={values.Fechadeultimaregla}
                  name="Fechadeultimaregla"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Fechadeultimaregla: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.AntecedentesginecoobstetricosGESTA}
                  name="AntecedentesginecoobstetricosGESTA"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      AntecedentesginecoobstetricosGESTA: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Antecedentes gineco-obstétricos GESTA
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.AntecedentesginecoobstetricosPARA}
                  name="AntecedentesginecoobstetricosPARA"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      AntecedentesginecoobstetricosPARA: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Antecedentes gineco-obstétricos PARTO{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.AntecedentesginecoobstetricosABORTOS}
                  name="AntecedentesginecoobstetricosABORTOS"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      AntecedentesginecoobstetricosABORTOS: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Antecedentes gineco-obstétricos ABORTOS
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.AntecedentesginecoobstetricosCESAREA}
                  name="AntecedentesginecoobstetricosCESAREA"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      AntecedentesginecoobstetricosCESAREA: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Antecedentes gineco-obstétricos CESAREA{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Selehapracticadomastografia}
                  name="Selehapracticadomastografia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Selehapracticadomastografia: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Se le ha practicado mastografía
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Motivos y Resultados </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.MotivosyResultados}
                  name="MotivosyResultados"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      MotivosyResultados: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.SelehapracticadoPapanicolau}
                  name="SelehapracticadoPapanicolau"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      SelehapracticadoPapanicolau: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Se le ha practicado Papanicolau
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fecha Papanicolau</label>
                <input
                  type="date"
                  className="form-control"
                  value={values.FechaPapanicolau}
                  name="FechaPapanicolau"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      FechaPapanicolau: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Fecha de última mastografia
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={values.FechaMastrografia}
                  name="FechaMastrografia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      FechaMastrografia: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Resultado</label>
                <textarea
                  className="form-control"
                  value={values.Resultado}
                  name="Resultado"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Resultado: e.target.value,
                    })
                  }
                >
                  {values.Resultado}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
