import { useState } from "react";
const styleTitle = {
  color: '#596875'
};

export default function Form_2() {
  const [values, setvalues] = useState({
    Cancer: "",
    Diabetes: "",
    EnfermedadesdelCorazon: "",
    EnfermedadesdelosRinones: "",
    EnfermedadesCerebrovasculares: "",
    Mentales: "",
    Suicidio: "",
    Otras: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Antecedentes Patológicos Familiares</h4>
            <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Cancer}
                  name="Cancer"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Cancer: e.target.checked,
                    });
                  }}
                />
                <label className="form-label">&nbsp;&nbsp;Cáncer</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Diabetes}
                  name="Diabetes"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Diabetes: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Diabetes</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.EnfermedadesdelCorazon}
                  name="EnfermedadesdelCorazon"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EnfermedadesdelCorazon: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Enfermedades del Corazón
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.PresionArterialAlta}
                  name="PresionArterialAlta"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PresionArterialAlta: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;PresionArterialAlta
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.EnfermedadesdelosRinones}
                  name="EnfermedadesdelosRinones"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EnfermedadesdelosRinones: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Enfermedades de los Riñones{" "}
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.EnfermedadesCerebrovasculares}
                  name="EnfermedadesCerebrovasculares"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EnfermedadesCerebrovasculares: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Enfermedades Cerebrovasculares
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Mentales}
                  name="Mentales"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Mentales: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Mentales</label>
              </div>

              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Suicidio}
                  name="Suicidio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Suicidio: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Suicidio</label>
              </div>

              <div className="form-outline col-lg-4">
                <label className="form-label">Otras - Especificar</label>
                <textarea
                  className="form-control"
                  value={values.Otras}
                  name="Otras"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Otras: e.target.value,
                    })
                  }
                >
                  {values.Otras}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
