import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_5() {
  const [values, setvalues] = useState({
    NumerodeHermanosVivos: "",
    EstadodeSaludHermanos: "",
    NumerodeHermanosMuertos: "",
    CausaMuerteHermanos: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Historia Familiar Hermanos</h4>
            <hr></hr>            
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Número de Hermanos Vivos</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NumerodeHermanosVivos}
                  name="NumerodeHermanosVivos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NumerodeHermanosVivos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Estado de Salud Hermanos </label>
                <textarea
                  className="form-control"
                  value={values.EstadodeSaludHermanos}
                  name="EstadodeSaludHermanos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstadodeSaludHermanos: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Número de Hermanos Muertos</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NumerodeHermanosMuertos}
                  name="NumerodeHermanosMuertos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NumerodeHermanosMuertos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Causa Muerte Hermanos</label>
                <textarea
                  className="form-control"
                  value={values.CausaMuerteHermanos}
                  name="CausaMuerteHermanos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CausaMuerteHermanos: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
