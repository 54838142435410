import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_10() {
  const [values, setvalues] = useState({
    Hautilizadodrogaspsicoactivas: "",
    Encasoafirmativoseñaletipodedroga: "",
    Encasoafirmativosenalefrecuencia: "",
    Encasoafirmativosenaleultimaocasion: "",
    Harecibidotratamientoporconsumodedrogas: "",
    Perteneceaalgunaasociaciondedrogadiccion: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Hábitos de Drogas</h4>
                    <hr></hr>   
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha utilizado drogas psicoactivas?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Hautilizadodrogaspsicoactivas"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Hautilizadodrogaspsicoactivas: e,
                    });
                  }}
                  value={values.Hautilizadodrogaspsicoactivas}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  En caso afirmativo señale tipo de droga{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Encasoafirmativoseñaletipodedroga}
                  name="Encasoafirmativoseñaletipodedroga"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Encasoafirmativoseñaletipodedroga: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  En caso afirmativo señale frecuencia
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Encasoafirmativosenalefrecuencia}
                  name="Encasoafirmativosenalefrecuencia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Encasoafirmativosenalefrecuencia: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  En caso afirmativo senale ultima ocasion{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Encasoafirmativosenaleultimaocasion}
                  name="Encasoafirmativosenaleultimaocasion"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Encasoafirmativosenaleultimaocasion: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha recibido tratamiento por consumo de drogas?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Harecibidotratamientoporconsumodedrogas"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Harecibidotratamientoporconsumodedrogas: e,
                    });
                  }}
                  value={values.Harecibidotratamientoporconsumodedrogas}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Pertenece a alguna asociación de drogadicción?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Perteneceaalgunaasociaciondedrogadiccion"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Perteneceaalgunaasociaciondedrogadiccion: e,
                    });
                  }}
                  value={values.Perteneceaalgunaasociaciondedrogadiccion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
