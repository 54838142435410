import { useCallback, useContext, useState } from "react";
import { Contacto } from "../service/contacto";
import Auth from "../utils/validateToken";

export default function useContacto() {
  const [state, setstate] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
  });

  const contacto = useCallback((data) => {
    setstate({ loading: true, error: false, message: "", success: false });
    Contacto(data)
      .then((response) => {
        if (response.success) {
          setstate({
            loading: false,
            error: false,
            message: response.data.certificadoid,
            success: true,
          });
        } else {
          setstate({
            loading: false,
            error: true,
            message: response.message,
            success: false,
          });
        }
      })
      .catch((err) => {
        setstate({ loading: false, error: true, message: err, success: false });
      });
  }, []);

  return {
    loading: state.loading,
    error: state.error,
    message: state.message,
    contacto,
    success: state.success,
  };
}
