import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_8() {
  const [values, setvalues] = useState({
    FumaActualmente: "",
    Fumabaanteriormente: "",
    Enqueanoempezoafumar: "",
    Cigarrosdiariosquefumaofumaba: "",
    Enqueanodejodefumar: "",
    Porquerazon: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Hábitos de Tabaco</h4>
                    <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Fuma Actualmente?</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.FumaActualmente}
                  name="FumaActualmente"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      FumaActualmente: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Fumaba anteriormente? </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Fumabaanteriormente"
                  onChange={(e) => {
                    setvalues({ ...values, Fumabaanteriormente: e });
                  }}
                  value={values.Fumabaanteriormente}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿En qué año empezó a fumar?
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Enqueanoempezoafumar}
                  name="Enqueanoempezoafumar"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Enqueanoempezoafumar: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Cigarros diarios que fuma o fumaba
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Cigarrosdiariosquefumaofumaba}
                  name="Cigarrosdiariosquefumaofumaba"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Cigarrosdiariosquefumaofumaba: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿En qué año dejó de fumar?{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Enqueanodejodefumar}
                  name="Enqueanodejodefumar"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Enqueanodejodefumar: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Por qué razón?</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Porquerazon}
                  name="Porquerazon"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Porquerazon: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
