import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_4() {
  const [values, setvalues] = useState({
    convidalaMadre: "",
    EdadMadre: "",
    EstadodeSaludMadre: "",
    EdadMadreasuMuerte: "",
    CausaMuerteMadre: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Historia Familiar Madre</h4>
            <hr></hr>            
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Se encuentra con vida la Madre?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="convidalaMadre"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      convidalaMadre: e,
                    });
                  }}
                  value={values.convidalaMadre}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Madre</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadMadre}
                  name="EdadMadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadMadre: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Estado de Salud Madre </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.EstadodeSaludMadre}
                  name="EstadodeSaludMadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstadodeSaludMadre: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Madre a su Muerte</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadMadreasuMuerte}
                  name="EdadMadreasuMuerte"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadMadreasuMuerte: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Causa Muerte Madre</label>
                <textarea
                  className="form-control"
                  value={values.CausaMuerteMadre}
                  name="CausaMuerteMadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CausaMuerteMadre: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
