function verification_typeof(value) {
  const valor = typeof value;
  switch (valor) {
    case "string":
      return value;
    case "number":
      return value;
    case "boolean":
      if (value) {
        return 1;
      } else {
        return 0;
      }
    case "object":
      if (value === null) {
        return "";
      } else {
        return value.value;
      }
    default:
      return "";
  }
}

function getValores(values) {
  return {
    subject: `Certificado  ${values.Nombre ?? ""} ${
      values.ApellidoPaterno ?? ""
    } ${values.ApellidoMaterno ?? ""}`,

    //Nuevos agregados
    cf_1973: verification_typeof(values.NombredelProyecto),
    cf_1975: verification_typeof(values.NombredelaProduccion),
    //////////////////

    cf_1971: verification_typeof(values.Nombre),
    cf_1487: verification_typeof(values.ApellidoPaterno),
    cf_1489: verification_typeof(values.ApellidoMaterno),
    cf_1493: verification_typeof(values.CURP),
    cf_1491: verification_typeof(values.RFC),
    cf_1497: verification_typeof(values.Ocupacion),
    cf_1495: verification_typeof(values.FechadeNacimiento),
    cf_1444: verification_typeof(values.Sexo),
    cf_1499: verification_typeof(values.LugardeResidencia),
    cf_1501: verification_typeof(values.Cancer),
    cf_1503: verification_typeof(values.Diabetes),
    cf_1505: verification_typeof(values.EnfermedadesdelCorazon),
    cf_1509: verification_typeof(values.EnfermedadesdelosRinones),
    cf_1511: verification_typeof(values.EnfermedadesCerebrovasculares),
    cf_1513: verification_typeof(values.Mentales),
    cf_1515: verification_typeof(values.Suicidio),
    cf_1517: verification_typeof(values.Otras),
    cf_1531: verification_typeof(values.convidaelPadre),
    cf_1521: verification_typeof(values.EdadPadre),
    cf_1523: verification_typeof(values.EstadodeSaludPadre),
    cf_1527: verification_typeof(values.EdadPadreasuMuerte),
    cf_1529: verification_typeof(values.CausaMuertePadre),
    cf_1533: verification_typeof(values.convidalaMadre),
    cf_1535: verification_typeof(values.EdadMadre),
    cf_1537: verification_typeof(values.EstadodeSaludMadre),
    cf_1539: verification_typeof(values.EdadMadreasuMuerte),
    cf_1541: verification_typeof(values.CausaMuerteMadre),
    cf_1543: verification_typeof(values.NumerodeHermanosVivos),
    cf_1545: verification_typeof(values.EstadodeSaludHermanos),
    cf_1547: verification_typeof(values.NumerodeHermanosMuertos),
    cf_1549: verification_typeof(values.CausaMuerteHermanos),
    cf_1551: verification_typeof(values.convidaConyuge),
    cf_1555: verification_typeof(values.EdadConyuge),
    cf_1553: verification_typeof(values.EstadodeSaludConyuge),
    cf_1557: verification_typeof(values.EdadConyugeasuMuerte),
    cf_1559: verification_typeof(values.NumerodeHijosVivos),
    cf_1561: verification_typeof(values.EstadodeSaludHijos),
    cf_1563: verification_typeof(values.NumerodeHijosMuertos),
    cf_1565: verification_typeof(values.CausadeMuerteHijos),
    cf_1567: verification_typeof(values.FumaActualmente),
    cf_1573: verification_typeof(values.Fumabaanteriormente),
    cf_1569: verification_typeof(values.Enqueanoempezoafumar),
    cf_1571: verification_typeof(values.Cigarrosdiariosquefumaofumaba),
    cf_1575: verification_typeof(values.Enqueanodejodefumar),
    cf_1577: verification_typeof(values.Porquerazon),
    cf_1579: verification_typeof(values.IngiereBebidasAlcoholicas),
    cf_1581: verification_typeof(values.Desdecuando),
    cf_1985: verification_typeof(values.Clase),
    cf_1585: verification_typeof(values.Frecuencia),
    cf_1591: verification_typeof(values.Otros),
    cf_1593: verification_typeof(values.Cantidadenmi),
    cf_1595: verification_typeof(values.Llegaalestadodeembriaguez),
    cf_1597: verification_typeof(values.Hatenidoaccidentesporelalcohol),
    cf_1599: verification_typeof(values.Sidejodebebersenalefechaycausa),
    cf_1601: verification_typeof(
      values.Cuantobebiaantesclasefrecuenciacantidad
    ),
    cf_1603: verification_typeof(
      values.Harecibidotratamientoporconsumodealcohol
    ),
    cf_1605: verification_typeof(
      values.Perteneceaalgunaasociaciondealcoholicos
    ),
    cf_1607: verification_typeof(values.Hautilizadodrogaspsicoactivas),
    cf_1609: verification_typeof(values.Encasoafirmativoseñaletipodedroga),
    cf_1611: verification_typeof(values.Encasoafirmativosenalefrecuencia),
    cf_1615: verification_typeof(values.Encasoafirmativosenaleultimaocasion),
    cf_1617: verification_typeof(
      values.Harecibidotratamientoporconsumodedrogas
    ),
    cf_1619: verification_typeof(
      values.Perteneceaalgunaasociaciondedrogadiccion
    ),
    cf_1621: verification_typeof(values.Havariadosupesoenelultimoano),
    cf_1625: verification_typeof(values.Kgsaumentados),
    cf_1627: verification_typeof(values.Kgsdisminuidos),
    cf_1631: verification_typeof(values.Causa),
    cf_1623: verification_typeof(
      values.Realizaregularmentealgunaactividadfisica
    ),
    cf_1629: verification_typeof(values.Tipodeejercicio),
    cf_1633: verification_typeof(values.Padeceactualmentealgunaenfermedad),
    cf_1635: verification_typeof(
      values.Enlosultimos2anoshaconsultadoalgunmedico
    ),
    cf_1637: verification_typeof(values.Estasujetoacualquiertratamientomedico),
    cf_1639: verification_typeof(values.Harecibidotransfusionesdesangre),
    cf_1641: verification_typeof(
      values.Haconsultadoporenfermedadtransmisionsexual
    ),
    cf_1643: verification_typeof(values.LehandichoquetieneSIDA),
    cf_1645: verification_typeof(
      values.Harecibidotratamientolascausasanteriores
    ),
    cf_1647: verification_typeof(
      values.Encasodecualquierrespuestapositivadescriba1
    ),
    cf_1989: verification_typeof(values.tipodeSangre),
    cf_1649: verification_typeof(values.soplodelcorazon),
    cf_1651: verification_typeof(values.Dolordepechooeneltorax),
    cf_1653: verification_typeof(values.Infartoenelcorazon),
    cf_1655: verification_typeof(values.Algunaotraenfermedaddecorazon),
    cf_1657: verification_typeof(values.Enfermedadesdecirculacion),
    cf_1659: verification_typeof(values.Presionarterialalta),
    cf_1661: verification_typeof(values.Bronquitiscronica),
    cf_1663: verification_typeof(values.Asma),
    cf_1665: verification_typeof(values.Tuberculosis),
    cf_1667: verification_typeof(values.Cancer),
    cf_1669: verification_typeof(values.Ulceradelduodeno),
    cf_1671: verification_typeof(values.Ulceradelestomago),
    cf_1673: verification_typeof(values.Enfermedaddelhigado),
    cf_1977: verification_typeof(values.Enfermedadesdelavesiculabiliar),
    cf_1979: verification_typeof(values.Enfermedadesdelintestinocolonorecto),
    cf_1689: verification_typeof(values.Enfermedadeselosrinones),
    cf_1693: verification_typeof(values.Enfermedadesdelavejiga),
    cf_1695: verification_typeof(values.Diabetes),
    cf_1697: verification_typeof(values.Alguntumor),
    cf_1699: verification_typeof(values.Paralisis),
    cf_1701: verification_typeof(values.Perdidadeconocimientooconvulsiones),
    cf_1703: verification_typeof(values.Astemiaadinamiayanorexia),
    cf_1705: verification_typeof(values.Cuadrosdiarreicosfrecuentes),
    cf_1707: verification_typeof(values.Hipertrofiaganglionar),
    cf_1709: verification_typeof(values.Diaforisisnocturna),
    cf_1711: verification_typeof(values.Enfermedadesdeloshuesos),
    cf_1713: verification_typeof(values.Transtornosnerviososomentales),
    cf_1981: verification_typeof(values.Algunaintervencionquirurgica),
    cf_1715: verification_typeof(values.Algunaotraenfermedad),
    cf_1741: verification_typeof(values.Describa),
    cf_1743: verification_typeof(values.Electrocardiogramas),
    cf_1745: verification_typeof(values.Analisisopruebasdelaboratorio),
    cf_1747: verification_typeof(values.Radiografias),
    cf_1749: verification_typeof(values.PruebaparadetectarSIDA),
    cf_1983: verification_typeof(values.Haestadohospitalizadoalgunavez),
    cf_1771: verification_typeof(values.SitioyFecha),
    cf_1755: verification_typeof(
      values.Encasodecualquierrespuestaafirmativadescriba
    ),
    cf_1757: verification_typeof(values.Endermedadesdeglandulasmamarias),
    cf_1759: verification_typeof(values.EnfermedadesdeUtero),
    cf_1761: verification_typeof(values.Estaembarazada),
    cf_1773: verification_typeof(values.Semanasdegestacionencasoafirmativo),
    cf_1767: verification_typeof(values.Fechadeultimaregla),
    cf_1775: verification_typeof(values.AntecedentesginecoobstetricosGESTA),
    cf_1777: verification_typeof(values.AntecedentesginecoobstetricosPARA),
    cf_1779: verification_typeof(values.AntecedentesginecoobstetricosABORTOS),
    cf_1781: verification_typeof(values.AntecedentesginecoobstetricosCESAREA),
    cf_1783: verification_typeof(values.Selehapracticadomastografia),
    cf_1785: verification_typeof(values.MotivosyResultados),
    cf_1789: verification_typeof(values.SelehapracticadoPapanicolau),
    cf_1791: verification_typeof(values.FechaPapanicolau),
    cf_1793: verification_typeof(values.Resultado),
    cf_1795: verification_typeof(values.Estaturaencms),
    cf_1797: verification_typeof(values.PesoenKgs),
    cf_1799: verification_typeof(values.Lomidio),
    cf_1801: verification_typeof(values.Lopeso),
    cf_1803: verification_typeof(values.IndicedeMasaCorporal),
    cf_1805: verification_typeof(values.GlucosaCapliar),
    cf_1807: verification_typeof(values.PerimetroenToraxInspiracionPlena),
    cf_1809: verification_typeof(values.PerimetroenToraxespiracionforzada),
    cf_1811: verification_typeof(values.Perimetrodelabdomenanivelombligo),
    cf_1813: verification_typeof(
      values.Pulsofrecuenciacardiacaenminutocompleto
    ),
    cf_1815: verification_typeof(values.Esritmico),
    cf_1817: verification_typeof(values.Numerodeextrasistolesporminuto),
    cf_1819: verification_typeof(
      values.PruebadeEsfuerzoFrecuenciaenminutocompleto
    ),
    cf_1821: verification_typeof(values.PruebadeEsfuerzoEsritmico),
    cf_1823: verification_typeof(
      values.PruebadeEsfuerzoPruebadeextrasoidespormin
    ),
    cf_1825: verification_typeof(values.PresionArterialMetodoAuscultatorio),
    cf_1827: verification_typeof(values.PresionArterialdiastólica),
    cf_1831: verification_typeof(
      values.Nivelespaciointercostalenquepalpaelcorazon
    ),
    cf_1833: verification_typeof(values.Existealgundatoanormaldepalpitacion),
    cf_1835: verification_typeof(values.Laauscultacionenfocosclasicosesnormal),
    cf_1837: verification_typeof(values.CrecimientoCardiaco),
    cf_1839: verification_typeof(values.Disnea),
    cf_1841: verification_typeof(values.Edema),
    cf_1843: verification_typeof(values.Constante),
    cf_1845: verification_typeof(values.Inconstante),
    cf_1847: verification_typeof(values.Transmitido),
    cf_1849: verification_typeof(values.Localizado),
    cf_1851: verification_typeof(values.Sistolico),
    cf_1853: verification_typeof(values.Presistolico),
    cf_1855: verification_typeof(values.Diastolico),
    cf_1857: verification_typeof(values.Suavegr12),
    cf_1859: verification_typeof(values.Moderadogr34),
    cf_1861: verification_typeof(values.Fuertegr56),
    cf_1865: verification_typeof(values.Despuesdelejercicioseincrementa),
    cf_1867: verification_typeof(values.Despuesdelejerciciosedesaparece),
    cf_1869: verification_typeof(values.Despuesdelejercicionocambia),
    cf_1871: verification_typeof(values.Despuesdelejerciciosedisminuye),
    cf_1873: verification_typeof(values.ImpresionDiagnostica),
    cf_1875: verification_typeof(values.Ausencia),
    cf_1877: verification_typeof(values.Protesis),
    cf_1879: verification_typeof(values.Amalgama),
    cf_1881: verification_typeof(values.Nombreytelefonodelodontologo),
    cf_1883: verification_typeof(values.Anormalidadensuaspecto),
    cf_1885: verification_typeof(values.ExisteDisnea),
    cf_1887: verification_typeof(values.ExisteDeformidad),
    cf_1889: verification_typeof(values.Faltaalgunmiembroopartedeel),
    cf_1891: verification_typeof(values.HayParalsiisoParesias),
    cf_1893: verification_typeof(values.Movimientosanormales),
    cf_1895: verification_typeof(values.Lamanchaesnormal),
    cf_1897: verification_typeof(values.Seapreciatranstornopsiquico),
    cf_1899: verification_typeof(values.Existecualquieranormalidad),
    cf_1901: verification_typeof(values.Enestadoanatomicodeojos),
    cf_1903: verification_typeof(values.Envisiondeojos),
    cf_1905: verification_typeof(values.Enaudiciondecadaoido),
    cf_1907: verification_typeof(values.Encavidadbucalylafaringe),
    cf_1909: verification_typeof(values.Enelcuello),
    cf_1911: verification_typeof(values.Entorax),
    cf_1913: verification_typeof(values.Encolumnavertebral),
    cf_1915: verification_typeof(values.Enabdomen),
    cf_1917: verification_typeof(values.Enlasextremidades),
    cf_1919: verification_typeof(values.Existealgundatoquesenalequefume),
    cf_1921: verification_typeof(
      values.Conoceenelexaminadoalgunfactorenhabitos
    ),
    cf_1923: verification_typeof(values.ConsideraelestadodesaludcomoNormal),
    cf_1925: verification_typeof(values.ConsideraelestadodesaludcomoDudoso),
    cf_1927: verification_typeof(values.ConsideraelestadodesaludcomoAnormal),
    cf_1929: verification_typeof(values.Recogiopersonalmentemuestradeorina),
    cf_1931: verification_typeof(values.DatosAdicionales),
    cf_1933: verification_typeof(values.NombredelMedico),
    cf_1935: verification_typeof(values.DomiciliodelMedico),
    cf_1937: verification_typeof(values.TelefonodelMedico),
    cf_1939: verification_typeof(values.Ciudad),
    cf_1941: verification_typeof(values.Fecha),
    cf_1943: verification_typeof(values.Solicitanteseidentificoconlacredencial),
    cf_1945: verification_typeof(values.SolicitanteautorizaIMacasaproductora),
    cf_1947: verification_typeof(values.Expedidopor),
    cf_1949: verification_typeof(values.Numero),
    cf_1951: verification_typeof(values.Vigencia),
    cf_1953: verification_typeof(values.ClaveyNombredelAgente),
    cf_1955: verification_typeof(values.Miconsultorio),
    cf_1957: verification_typeof(values.Sudomicilo),
    cf_1959: verification_typeof(values.Otrolugar),
    cf_1961: verification_typeof(values.Especificarotrolugar),
    cf_1963: verification_typeof(values.NombreCompletodelExaminador),
    cf_1965: verification_typeof(values.TelefonodelExaminador),
    cf_1967: verification_typeof(values.DomiciliodelExaminador),
    cf_1969: verification_typeof(values.CedulaProfesionaldelExaminador),

    // agregar nuevos

    cf_2087: verification_typeof(values.ExisteCompania),
    cf_2089: verification_typeof(values.UltimaVezExamen),
    cf_2091: verification_typeof(values.ConocimientoBuenaSalud),
    cf_2093: verification_typeof(values.NegativaDetallesCompletos),
    cf_2095: verification_typeof(values.EstaraEnOtroProyecto),
    cf_2097: verification_typeof(values.ParticipaActividadesDepórtivas),
    cf_2099: verification_typeof(values.ActuaraActividadFisica),
    cf_2101: verification_typeof(values.IndicarPuestoResponsabilida),
    cf_2103: verification_typeof(values.HaraPropiosStunts),
    cf_2105: verification_typeof(values.DisposicionContractual),
    cf_2107: verification_typeof(values.AfirmativaHorasDiasSemana),
    cf_2109: verification_typeof(values.CuentaFechaParoContrato),
  };
}

export { verification_typeof, getValores };
