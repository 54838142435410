import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_22() {
  const [values, setvalues] = useState({
    Ciudad: "",
    Fecha: "",
    Solicitanteseidentificoconlacredencial: "",
    SolicitanteautorizaIMacasaproductora: "",
    Expedidopor: "",
    Numero: "",
    Vigencia: "",
    ClaveyNombredelAgente: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Información del Certificado</h4>
                    <hr></hr> 
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Ciudad</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Ciudad}
                  name="Ciudad"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Ciudad: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fecha</label>
                <input
                  type="date"
                  className="form-control"
                  value={values.Fecha}
                  name="Fecha"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Fecha: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Solicitante se identificó con la credencial
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Solicitanteseidentificoconlacredencial}
                  name="Solicitanteseidentificoconlacredencial"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Solicitanteseidentificoconlacredencial: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.SolicitanteautorizaIMacasaproductora}
                  name="SolicitanteautorizaIMacasaproductora"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      SolicitanteautorizaIMacasaproductora: e.target.checked
                    })
                  }
                />
                <label className="form-label">
                  ¿Solicitante autoriza IM a casa productora?
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Expedido por</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Expedidopor}
                  name="Expedidopor"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Expedidopor: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Número</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Numero}
                  name="Numero"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Numero: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Vigencia</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Vigencia}
                  name="Vigencia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Vigencia: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Clave y Nombre del Agente</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.ClaveyNombredelAgente}
                  name="ClaveyNombredelAgente"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ClaveyNombredelAgente: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
