import { useState } from "react";
import Select from "react-select";
import { deportes, opciones, puesto } from "../../utils/initialParams";
const styleTitle = {
  color: "#596875",
};
export default function Form_20() {
  const [values, setvalues] = useState({
    DatosAdicionales: "",
    ExisteCompania: "",
    UltimaVezExamen: "",
    ConocimientoBuenaSalud: "",
    NegativaDetallesCompletos: "",
    EstaraEnOtroProyecto: "",
    ParticipaActividadesDepórtivas: "",
    ActuaraActividadFisica: "",
    IndicarPuestoResponsabilida: "",
    HaraPropiosStunts: "",
    DisposicionContractual: "",
    AfirmativaHorasDiasSemana: "",
    CuentaFechaParoContrato: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
            <h4 style={styleTitle}>Datos Adicionales</h4>
            <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Datos Adicionales</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.DatosAdicionales}
                  name="DatosAdicionales"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      DatosAdicionales: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-8">
                <label className="form-label">
                  De acuerdo a su conocimiento, existe alguna compañía que haya
                  declinado (rehusado) asegurarlo o que le haya impuesto algunos
                  términos especiales en relación a su aceptación dentro del
                  seguro del elenco, seguro sin aparición en pantalla, seguro de
                  accidentes, enfermedades o seguro de vida?{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.ExisteCompania}
                  name="ExisteCompania"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ExisteCompania: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Cuando fue la ultima vez que tuvo un examen médico completo?{" "}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.UltimaVezExamen}
                  name="UltimaVezExamen"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      UltimaVezExamen: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  De acuerdo a su a su conocimiento y convicción, se encuentra
                  usted en buena salud y libre de cualquier enfermedad o
                  impedimento físico?
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.ConocimientoBuenaSalud}
                  name="ConocimientoBuenaSalud"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ConocimientoBuenaSalud: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Si la respuesta ha sido negativa, favor de proporcionar
                  detalles completos{" "}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.NegativaDetallesCompletos}
                  name="NegativaDetallesCompletos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NegativaDetallesCompletos: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Durante el tiempo que dure la producción de esta película,
                  cortometraje, proyecto ¿estará usted en cualquier otra
                  película o proyecto, foro, gira o compromiso profesional?
                  Favor de proporcionar fechas y detalles particulares.
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={values.EstaraEnOtroProyecto}
                  name="EstaraEnOtroProyecto"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstaraEnOtroProyecto: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Usted participa o práctica en actividades deportivas como:
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={deportes}
                  name="ParticipaActividadesDepórtivas"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      ParticipaActividadesDepórtivas: e,
                    });
                  }}
                  value={values.ParticipaActividadesDepórtivas}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Actuará usted en cualquier actividad física que requiera
                  práctica o entrenamiento?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="ActuaraActividadFisica"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      ActuaraActividadFisica: e,
                    });
                  }}
                  value={values.ActuaraActividadFisica}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Favor de indicar el puesto o responsabilidad que tendrá usted
                  en esta producción
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={puesto}
                  name="IndicarPuestoResponsabilida"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      IndicarPuestoResponsabilida: e,
                    });
                  }}
                  value={values.IndicarPuestoResponsabilida}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Hará usted sus propios stunts?
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.HaraPropiosStunts}
                  name="HaraPropiosStunts"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      HaraPropiosStunts: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Existe alguna disposición contractual que marque el máximo
                  número de horas por semana, día, días por semana que usted
                  trabajará?
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.DisposicionContractual}
                  name="DisposicionContractual"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      DisposicionContractual: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Si la respuesta es afirmativa, favor de indicar: horas y días
                  por semana.
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.AfirmativaHorasDiasSemana}
                  name="AfirmativaHorasDiasSemana"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      AfirmativaHorasDiasSemana: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                Si la respuesta es afirmativa, favor de indicar: horas y días
                  por semana.
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="CuentaFechaParoContrato"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      CuentaFechaParoContrato: e,
                    });
                  }}
                  value={values.CuentaFechaParoContrato}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
