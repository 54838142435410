import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};

export default function Form_3() {
  const [values, setvalues] = useState({
    convidaelPadre: "",
    EdadPadre: "",
    EstadodeSaludPadre: "",
    EdadPadreasuMuerte: "",
    CausaMuertePadre: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Historia Familiar Padre</h4>
            <hr></hr>            
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Se encuentra con vida el Padre?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="convidaelPadre"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      convidaelPadre: e,
                    });
                  }}
                  value={values.convidaelPadre}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Padre</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadPadre}
                  name="EdadPadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadPadre: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Estado de Salud Padre </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.EstadodeSaludPadre}
                  name="EstadodeSaludPadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstadodeSaludPadre: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Padre a su Muerte</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadPadreasuMuerte}
                  name="EdadPadreasuMuerte"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadPadreasuMuerte: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Causa Muerte Padre</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={values.CausaMuertePadre}
                  name="CausaMuertePadre"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CausaMuertePadre: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
