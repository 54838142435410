/* eslint-disable no-unused-vars */
import { UserContextProvider } from "./context/userContext";
import PrivateRoute from "./router/PrivateRoute";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Formulario from "./pages/formulario";
import Offline from "./pages/offline";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/offline" element={<Offline />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/certificado" element={<Formulario />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
