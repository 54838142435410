import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_14() {
  const [values, setvalues] = useState({
    Electrocardiogramas: "",
    Analisisopruebasdelaboratorio: "",
    Radiografias: "",
    PruebaparadetectarSIDA: "",
    Haestadohospitalizadoalgunavez: "",
    SitioyFecha: "",
    Encasodecualquierrespuestaafirmativadescriba: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Alguna vez le han practicado</h4>
                    <hr></hr>  
            <div className="row">
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Electrocardiogramas}
                  name="Electrocardiogramas"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Electrocardiogramas: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Electrocardiogramas
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Analisisopruebasdelaboratorio}
                  name="Analisisopruebasdelaboratorio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Analisisopruebasdelaboratorio: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Análisis o pruebas de laboratorio
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Radiografias}
                  name="Radiografias"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Radiografias: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Radiografías </label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.PruebaparadetectarSIDA}
                  name="PruebaparadetectarSIDA"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      PruebaparadetectarSIDA: e.target.checked,
                    })
                  }
                />
                <label className="form-label">
                  &nbsp;&nbsp;Prueba para detectar SIDA
                </label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha estado hospitalizado alguna vez?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Haestadohospitalizadoalgunavez"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Haestadohospitalizadoalgunavez: e
                    });
                  }}
                  value={values.Haestadohospitalizadoalgunavez}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fecha</label>
                <input
                  type="date"
                  className="form-control"
                  value={values.SitioyFecha}
                  name="SitioyFecha"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      SitioyFecha: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  En caso de cualquier respuesta afirmativa describa
                </label>
                <textarea
                  className="form-control"
                  value={values.Encasodecualquierrespuestaafirmativadescriba}
                  name="Encasodecualquierrespuestaafirmativadescriba"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Encasodecualquierrespuestaafirmativadescriba: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
