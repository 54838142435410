import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_23() {
  const [values, setvalues] = useState({
    Miconsultorio: "",
    Sudomicilo: "",
    Otrolugar: "",
    Especificarotrolugar: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Practicado en</h4>
            <hr></hr>  
            <div className="row">
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Miconsultorio}
                  name="Miconsultorio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Miconsultorio: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Mi consultorio</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Sudomicilo}
                  name="Sudomicilo"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Sudomicilo: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Su domicilo</label>
              </div>
              <div className="form-outline col-lg-4">
                <input
                  type="checkbox"
                  value={values.Otrolugar}
                  name="Otrolugar"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Otrolugar: e.target.checked,
                    })
                  }
                />
                <label className="form-label">&nbsp;&nbsp;Otro lugar</label>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Especificar otro lugar</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Especificarotrolugar}
                  name="Especificarotrolugar"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Especificarotrolugar: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
