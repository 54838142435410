import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_6() {
  const [values, setvalues] = useState({
    convidaConyuge: "",
    EdadConyuge: "",
    EstadodeSaludConyuge: "",
    EdadConyugeasuMuerte: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Historia Familiar Cónyuge</h4>
                    <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Se encuentra con vida Cónyuge?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="convidalaConyuge"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      convidaConyuge: e,
                    });
                  }}
                  value={values.convidaConyuge}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Cónyuge</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadConyuge}
                  name="EdadConyuge"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadConyuge: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Estado de Salud Cónyuge</label>
                <textarea
                  className="form-control"
                  value={values.EstadodeSaludConyuge}
                  name="EstadodeSaludConyuge"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstadodeSaludConyuge: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Edad Cónyuge a su Muerte </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.EdadConyugeasuMuerte}
                  name="EdadConyugeasuMuerte"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EdadConyugeasuMuerte: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
