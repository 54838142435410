import React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import useUser from "../hooks/useLogin";

export default function PrivateRoute(props) {
  const { token } = useUser();
  const { pathname } = useLocation();

  if (token) {
    if (pathname === "/") {
      return <Navigate to="/certificado" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to={"/login"} />;
  }
}