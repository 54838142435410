import { useState } from "react";
import Select from "react-select";
import { opciones, opcionesFrecuencia } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_9() {
  const [values, setvalues] = useState({
    IngiereBebidasAlcoholicas: "",
    Desdecuando: "",
    Clase: "",
    Frecuencia: "",
    Otros: "",
    Cantidadenmi: "",
    Llegaalestadodeembriaguez: "",
    Hatenidoaccidentesporelalcohol: "",
    Sidejodebebersenalefechaycausa: "",
    Cuantobebiaantesclasefrecuenciacantidad: "",
    Harecibidotratamientoporconsumodealcohol: "",
    Perteneceaalgunaasociaciondealcoholicos: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Hábitos de Alcohol</h4>
                    <hr></hr>
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ingiere Bebidas Alcoholicas?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="IngiereBebidasAlcoholicas"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      IngiereBebidasAlcoholicas: e,
                    });
                  }}
                  value={values.IngiereBebidasAlcoholicas}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">¿Desde cuándo? </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Desdecuando}
                  name="Desdecuando"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Desdecuando: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Clase</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Clase}
                  name="Clase"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Clase: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Frecuencia </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opcionesFrecuencia}
                  name="Frecuencia"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Frecuencia: e,
                    });
                  }}
                  value={values.Frecuencia}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Otros </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Otros}
                  name="Otros"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Otros: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Cantidad en mi</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Cantidadenmi}
                  name="Cantidadenmi"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Cantidadenmi: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Llega al estado de embriaguez?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Llegaalestadodeembriaguez"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Llegaalestadodeembriaguez: e,
                    });
                  }}
                  value={values.Llegaalestadodeembriaguez}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha tenido accidentes por el alcohol?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Hatenidoaccidentesporelalcohol"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Hatenidoaccidentesporelalcohol: e,
                    });
                  }}
                  value={values.Hatenidoaccidentesporelalcohol}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Si dejó de beber señale fecha
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={values.Sidejodebebersenalefechaycausa}
                  name="Sidejodebebersenalefechaycausa"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Sidejodebebersenalefechaycausa: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Cuánto bebia antes clase-frecuencia-cantidad
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Cuantobebiaantesclasefrecuenciacantidad}
                  name="Cuantobebiaantesclasefrecuenciacantidad"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Cuantobebiaantesclasefrecuenciacantidad: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha recibido tratamiento por consumo de alcohol?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Harecibidotratamientoporconsumodealcohol"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Harecibidotratamientoporconsumodealcohol: e,
                    });
                  }}
                  value={values.Harecibidotratamientoporconsumodealcohol}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Pertenece a alguna asociación de alcoholicos?{" "}
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Perteneceaalgunaasociaciondealcoholicos"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Perteneceaalgunaasociaciondealcoholicos: e,
                    });
                  }}
                  value={values.Perteneceaalgunaasociaciondealcoholicos}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
