import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_7() {
  const [values, setvalues] = useState({
    NumerodeHijosVivos: "",
    EstadodeSaludHijos: "",
    NumerodeHijosMuertos: "",
    CausadeMuerteHijos: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Historia Familiar Hijos</h4>
            <hr></hr>            
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Número de Hijos Vivos</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NumerodeHijosVivos}
                  name="NumerodeHijosVivos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NumerodeHijosVivos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Estado de Salud Hijos </label>
                <textarea
                  className="form-control"
                  value={values.EstadodeSaludHijos}
                  name="EstadodeSaludHijos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      EstadodeSaludHijos: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Número de Hijos Muertos</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NumerodeHijosMuertos}
                  name="NumerodeHijosMuertos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NumerodeHijosMuertos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Causa de Muerte Hijos</label>
                <textarea
                  className="form-control"
                  value={values.CausadeMuerteHijos}
                  name="CausadeMuerteHijos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CausadeMuerteHijos: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
