import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_17() {
  const [values, setvalues] = useState({
    Constante: "",
    Inconstante: "",
    Transmitido: "",
    Localizado: "",
    Sistolico: "",
    Presistolico: "",
    Diastolico: "",
    Suavegr12: "",
    Moderadogr34: "",
    Fuertegr56: "",
    Despuesdelejercicioseincrementa: "",
    Despuesdelejerciciosedesaparece: "",
    Despuesdelejercicionocambia: "",
    Despuesdelejerciciosedisminuye: "",
    ImpresionDiagnostica: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>En Presencia de Soplo Cardiaco Describa</h4>
                    <hr></hr>  
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Constante </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Constante}
                  name="Constante"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Constante: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Inconstante </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Inconstante}
                  name="Inconstante"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Inconstante: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Transmitido </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Transmitido}
                  name="Transmitido"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Transmitido: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Localizado </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Localizado}
                  name="Localizado"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Localizado: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Sistólico </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Sistolico}
                  name="Sistolico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Sistolico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Presistólico </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Presistolico}
                  name="Presistolico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Presistolico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Diastólico </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Diastolico}
                  name="Diastolico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Diastolico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Suave gr 1-2</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Suavegr12}
                  name="Suavegr12"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Suavegr12: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Moderadogr34</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Moderadogr34}
                  name="Moderadogr34"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Moderadogr34: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Fuerte gr 5-6</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Fuertegr56}
                  name="Fuertegr56"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Fuertegr56: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Después del ejercicio se incrementa
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Despuesdelejercicioseincrementa}
                  name="Despuesdelejercicioseincrementa"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Despuesdelejercicioseincrementa: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Después del ejercicio se desaparece{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Despuesdelejerciciosedesaparece}
                  name="Despuesdelejerciciosedesaparece"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Despuesdelejerciciosedesaparece: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Después del ejercicio no cambia
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Despuesdelejercicionocambia}
                  name="Despuesdelejercicionocambia"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Despuesdelejercicionocambia: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Después del ejercicio se disminuye{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Despuesdelejerciciosedisminuye}
                  name="Despuesdelejerciciosedisminuye"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Despuesdelejerciciosedisminuye: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Impresión Diagnostica</label>
                <textarea
                  className="form-control"
                  value={values.ImpresionDiagnostica}
                  name="ImpresionDiagnostica"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      ImpresionDiagnostica: e.target.value,
                    })
                  }
                >
                  {values.ImpresionDiagnostica}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
