/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useContacto from "../../hooks/useContacto";
import useLogin from "../../hooks/useLogin";
import Swal from "sweetalert2";
import Select from "react-select";
import { Detector } from "react-detect-offline";

const options = [
  { value: "Femenino", label: "Femenino" },
  { value: "Masculino", label: "Masculino" },
];
const opciones = [
  { value: "1", label: "Si" },
  { value: "0", label: "No" },
];
const opcionesFrecuencia = [
  { value: "Diaria", label: "Diaria" },
  { value: "Semanal", label: "Semanal" },
  { value: "Quincenal", label: "Quincenal" },
  { value: "Mensual", label: "Mensual" },
  { value: "Otros", label: "Otros" },
];
const tipodeSangre = [
  { value: "A positivo (A +)", label: "A positivo (A +)" },
  { value: "A negativo (A-)", label: "A negativo (A-)" },
  { value: "B positivo (B +)", label: "B positivo (B +)" },
  { value: "B negativo (B-)", label: "B negativo (B-)" },
  { value: "AB positivo (AB+)", label: "AB positivo (AB+)" },
  { value: "AB negativo (AB-)", label: "AB negativo (AB-)" },
  { value: "O positivo (O+)", label: "O positivo (O+)" },
  { value: "O negativo (O-)", label: "O negativo (O-)" },
];
const loginSchema = Yup.object().shape({});
export default function Index() {
  const [tipo, settipo] = useState("");
  const [total, settotal] = useState(0);
  const navigate = useNavigate();
  const { error, contacto, message, success } = useContacto();
  const { logout } = useLogin();
  const closeSession = () => {
    logout();

    navigate("/");
  };

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const getInformacion = () => {
    const valores = localStorage.getItem("formulario");

    if (valores) {
      settotal(JSON.parse(valores).length);
    } else {
      settotal(0);
    }
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        title: "Certificado médico",
        text: "Certificado guardado con exito",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [error, message]);
  return (
    <>
      <Formik
        initialValues={{}}
        validationSchema={loginSchema}
        onSubmit={(values, { resetForm }) => {
          Swal.fire({
            title: "Espere",
            text: "Guardando contacto",
            imageUrl: "logo-login.png",
            imageWidth: 500,
            imageHeight: 100,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal-loader",
            },
          });
          const infor = {
            subject:
              "Certificado " +
              values.Nombre +
              " " +
              values.ApellidoPaterno +
              " " +
              values.ApellidoMaterno,
            cf_1971: values.Nombre,
            cf_1487: values.ApellidoPaterno,
            cf_1489: values.ApellidoMaterno,
            cf_1493: values.CURP,
            cf_1491: values.RFC,
            cf_1497: values.Ocupacion,
            cf_1495: values.FechadeNacimiento,
            cf_1444: values.Sexo,
            cf_1499: values.LugardeResidencia,
            cf_1501: values.Cancer,
            cf_1503: values.Diabetes,
            cf_1505: values.EnfermedadesdelCorazon,
            cf_1509: values.EnfermedadesdelosRinones,
            cf_1511: values.EnfermedadesCerebrovasculares,
            cf_1513: values.Mentales,
            cf_1515: values.Suicidio,
            cf_1517: values.Otras,
            cf_1531: values.convidaelPadre,
            cf_1521: values.EdadPadre,
            cf_1523: values.EstadodeSaludPadre,
            cf_1527: values.EdadPadreasuMuerte,
            cf_1529: values.CausaMuertePadre,
            cf_1533: values.convidalaMadre,
            cf_1535: values.EdadMadre,
            cf_1537: values.EstadodeSaludMadre,
            cf_1539: values.EdadMadreasuMuerte,
            cf_1541: values.CausaMuerteMadre,
            cf_1543: values.NumerodeHermanosVivos,
            cf_1545: values.EstadodeSaludHermanos,
            cf_1547: values.NumerodeHermanosMuertos,
            cf_1549: values.CausaMuerteHermanos,
            cf_1551: values.convidaConyuge,
            cf_1555: values.EdadConyuge,
            cf_1553: values.EstadodeSaludConyuge,
            cf_1557: values.EdadConyugeasuMuerte,
            cf_1559: values.NumerodeHijosVivos,
            cf_1561: values.EstadodeSaludHijos,
            cf_1563: values.NumerodeHijosMuertos,
            cf_1565: values.CausadeMuerteHijos,
            cf_1567: values.FumaActualmente,
            cf_1573: values.Fumabaanteriormente,
            cf_1569: values.Enqueanoempezoafumar,
            cf_1571: values.Cigarrosdiariosquefumaofumaba,
            cf_1575: values.Enqueanodejodefumar,
            cf_1577: values.Porquerazon,
            cf_1579: values.IngiereBebidasAlcoholicas,
            cf_1581: values.Desdecuando,
            cf_1985: values.Clase,
            cf_1585: values.Frecuencia,
            cf_1591: values.Otros,
            cf_1593: values.Cantidadenmi,
            cf_1595: values.Llegaalestadodeembriaguez,
            cf_1597: values.Hatenidoaccidentesporelalcohol,
            cf_1599: values.Sidejodebebersenalefechaycausa,
            cf_1601: values.Cuantobebiaantesclasefrecuenciacantidad,
            cf_1603: values.Harecibidotratamientoporconsumodealcohol,
            cf_1605: values.Perteneceaalgunaasociaciondealcoholicos,
            cf_1607: values.Hautilizadodrogaspsicoactivas,
            cf_1609: values.Encasoafirmativoseñaletipodedroga,
            cf_1611: values.Encasoafirmativosenalefrecuencia,
            cf_1615: values.Encasoafirmativosenaleultimaocasion,
            cf_1617: values.Harecibidotratamientoporconsumodedrogas,
            cf_1619: values.Perteneceaalgunaasociaciondedrogadiccion,
            cf_1621: values.Havariadosupesoenelultimoano,
            cf_1625: values.Kgsaumentados,
            cf_1627: values.Kgsdisminuidos,
            cf_1631: values.Causa,
            cf_1623: values.Realizaregularmentealgunaactividadfisica,
            cf_1629: values.Tipodeejercicio,
            cf_1633: values.Padeceactualmentealgunaenfermedad,
            cf_1635: values.Enlosultimos2anoshaconsultadoalgunmedico,
            cf_1637: values.Estasujetoacualquiertratamientomedico,
            cf_1639: values.Harecibidotransfusionesdesangre,
            cf_1641: values.Haconsultadoporenfermedadtransmisionsexual,
            cf_1643: values.LehandichoquetieneSIDA,
            cf_1645: values.Harecibidotratamientolascausasanteriores,
            cf_1647: values.Encasodecualquierrespuestapositivadescriba1,
            cf_1989: values.tipodeSangre,
            cf_1649: values.soplodelcorazon,
            cf_1651: values.Dolordepechooeneltorax,
            cf_1653: values.Infartoenelcorazon,
            cf_1655: values.Algunaotraenfermedaddecorazon,
            cf_1657: values.Enfermedadesdecirculacion,
            cf_1659: values.Presionarterialalta,
            cf_1661: values.Bronquitiscronica,
            cf_1663: values.Asma,
            cf_1665: values.Tuberculosis,
            cf_1667: values.Cancer,
            cf_1669: values.Ulceradelduodeno,
            cf_1671: values.Ulceradelestomago,
            cf_1673: values.Enfermedaddelhigado,
            cf_1977: values.Enfermedadesdelavesiculabiliar,
            cf_1979: values.Enfermedadesdelintestinocolonorecto,
            cf_1689: values.Enfermedadeselosrinones,
            cf_1693: values.Enfermedadesdelavejiga,
            cf_1695: values.Diabetes,
            cf_1697: values.Alguntumor,
            cf_1699: values.Paralisis,
            cf_1701: values.Perdidadeconocimientooconvulsiones,
            cf_1703: values.Astemiaadinamiayanorexia,
            cf_1705: values.Cuadrosdiarreicosfrecuentes,
            cf_1707: values.Hipertrofiaganglionar,
            cf_1709: values.Diaforisisnocturna,
            cf_1711: values.Enfermedadesdeloshuesos,
            cf_1713: values.Transtornosnerviososomentales,
            cf_1981: values.Algunaintervencionquirurgica,
            cf_1715: values.Algunaotraenfermedad,
            cf_1741: values.Describa,
            cf_1743: values.Electrocardiogramas,
            cf_1745: values.Analisisopruebasdelaboratorio,
            cf_1747: values.Radiografias,
            cf_1749: values.PruebaparadetectarSIDA,
            cf_1983: values.Haestadohospitalizadoalgunavez,
            cf_1771: values.SitioyFecha,
            cf_1755: values.Encasodecualquierrespuestaafirmativadescriba,
            cf_1757: values.Endermedadesdeglandulasmamarias,
            cf_1759: values.EnfermedadesdeUtero,
            cf_1761: values.Estaembarazada,
            cf_1773: values.Semanasdegestacionencasoafirmativo,
            cf_1767: values.Fechadeultimaregla,
            cf_1775: values.AntecedentesginecoobstetricosGESTA,
            cf_1777: values.AntecedentesginecoobstetricosPARA,
            cf_1779: values.AntecedentesginecoobstetricosABORTOS,
            cf_1781: values.AntecedentesginecoobstetricosCESAREA,
            cf_1783: values.Selehapracticadomastografia,
            cf_1785: values.MotivosyResultados,
            cf_1789: values.SelehapracticadoPapanicolau,
            cf_1791: values.FechaPapanicolau,
            cf_1793: values.Resultado,
            cf_1795: values.Estaturaencms,
            cf_1797: values.PesoenKgs,
            cf_1799: values.Lomidio,
            cf_1801: values.Lopeso,
            cf_1803: values.IndicedeMasaCorporal,
            cf_1805: values.GlucosaCapliar,
            cf_1807: values.PerimetroenToraxInspiracionPlena,
            cf_1809: values.PerimetroenToraxespiracionforzada,
            cf_1811: values.Perimetrodelabdomenanivelombligo,
            cf_1813: values.Pulsofrecuenciacardiacaenminutocompleto,
            cf_1815: values.Esritmico,
            cf_1817: values.Numerodeextrasistolesporminuto,
            cf_1819: values.PruebadeEsfuerzoFrecuenciaenminutocompleto,
            cf_1821: values.PruebadeEsfuerzoEsritmico,
            cf_1823: values.PruebadeEsfuerzoPruebadeextrasoidespormin,
            cf_1825: values.PresionArterialMetodoAuscultatorio,
            cf_1827: values.PresionArterialdiastólica,
            cf_1831: values.Nivelespaciointercostalenquepalpaelcorazon,
            cf_1833: values.Existealgundatoanormaldepalpitacion,
            cf_1835: values.Laauscultacionenfocosclasicosesnormal,
            cf_1837: values.CrecimientoCardiaco,
            cf_1839: values.Disnea,
            cf_1841: values.Edema,
            cf_1843: values.Constante,
            cf_1845: values.Inconstante,
            cf_1847: values.Transmitido,
            cf_1849: values.Localizado,
            cf_1851: values.Sistolico,
            cf_1853: values.Presistolico,
            cf_1855: values.Diastolico,
            cf_1857: values.Suavegr12,
            cf_1859: values.Moderadogr34,
            cf_1861: values.Fuertegr56,
            cf_1865: values.Despuesdelejercicioseincrementa,
            cf_1867: values.Despuesdelejerciciosedesaparece,
            cf_1869: values.Despuesdelejercicionocambia,
            cf_1871: values.Despuesdelejerciciosedisminuye,
            cf_1873: values.ImpresionDiagnostica,
            cf_1875: values.Ausencia,
            cf_1877: values.Protesis,
            cf_1879: values.Amalgama,
            cf_1881: values.Nombreytelefonodelodontologo,
            cf_1883: values.Anormalidadensuaspecto,
            cf_1885: values.ExisteDisnea,
            cf_1887: values.ExisteDeformidad,
            cf_1889: values.Faltaalgunmiembroopartedeel,
            cf_1891: values.HayParalsiisoParesias,
            cf_1893: values.Movimientosanormales,
            cf_1895: values.Lamanchaesnormal,
            cf_1897: values.Seapreciatranstornopsiquico,
            cf_1899: values.Existecualquieranormalidad,
            cf_1901: values.Enestadoanatomicodeojos,
            cf_1903: values.Envisiondeojos,
            cf_1905: values.Enaudiciondecadaoido,
            cf_1907: values.Encavidadbucalylafaringe,
            cf_1909: values.Enelcuello,
            cf_1911: values.Entorax,
            cf_1913: values.Encolumnavertebral,
            cf_1915: values.Enabdomen,
            cf_1917: values.Enlasextremidades,
            cf_1919: values.Existealgundatoquesenalequefume,
            cf_1921: values.Conoceenelexaminadoalgunfactorenhabitos,
            cf_1923: values.ConsideraelestadodesaludcomoNormal,
            cf_1925: values.ConsideraelestadodesaludcomoDudoso,
            cf_1927: values.ConsideraelestadodesaludcomoAnormal,
            cf_1929: values.Recogiopersonalmentemuestradeorina,
            cf_1931: values.DatosAdicionales,
            cf_1933: values.NombredelMedico,
            cf_1935: values.DomiciliodelMedico,
            cf_1937: values.TelefonodelMedico,
            cf_1939: values.Ciudad,
            cf_1941: values.Fecha,
            cf_1943: values.Solicitanteseidentificoconlacredencial,
            cf_1945: values.SolicitanteautorizaIMacasaproductora,
            cf_1947: values.Expedidopor,
            cf_1949: values.Numero,
            cf_1951: values.Vigencia,
            cf_1953: values.ClaveyNombredelAgente,
            cf_1955: values.Miconsultorio,
            cf_1957: values.Sudomicilo,
            cf_1959: values.Otrolugar,
            cf_1961: values.Especificarotrolugar,
            cf_1963: values.NombreCompletodelExaminador,
            cf_1965: values.TelefonodelExaminador,
            cf_1967: values.DomiciliodelExaminador,
            cf_1969: values.CedulaProfesionaldelExaminador,
          };

          if (tipo === 0) {
            const historial = localStorage.getItem("formulario");
            if (historial) {
              const historialArray = JSON.parse(historial);
              historialArray.push(values);
              localStorage.setItem(
                "formulario",
                JSON.stringify(historialArray)
              );

              resetForm({});
              getInformacion();
            } else {
              const historialArray = [];
              historialArray.push(values);
              localStorage.setItem(
                "formulario",
                JSON.stringify(historialArray)
              );

              resetForm({});
              getInformacion();
            }
          } else {
            const historial = localStorage.getItem("formulario");

            if (historial) {
            } else {
              Swal.fire({
                title: "Espere",
                text: "Guardando informacion",
                imageUrl: "logo-login.png",
                imageWidth: 500,
                imageHeight: 100,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                  popup: "swal-loader",
                },
              });
              contacto(infor);
            }
          }
        }}
      >
        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <img className="img-fluid" src="logo-login.png" alt="" />
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarColor01"
                ></div>
                <div className="d-flex col-lg-2 col-12 justify-content-end text-center">
                  <button
                    className="btn btn-primary my-2 my-sm-0"
                    type="button"
                    onClick={closeSession}
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </nav>
            <section className="vh-100">
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-3 text-center">
                          {total !== "" && (
                            <div className="alert alert-primary" role="alert">
                              <strong>
                                <i className="fas fa-check-circle"></i>
                              </strong>
                              <span>
                                Tienes un total de {total} formularios guardados
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Nombre</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Nombre}
                            onChange={(e) =>
                              setFieldValue("Nombre", e.target.value)
                            }
                          />
                          {touched.Nombre && errors.Nombre && (
                            <div>{errors.Nombre}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Apelido Paterno</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.ApellidoPaterno}
                            onChange={(e) =>
                              setFieldValue("ApellidoPaterno", e.target.value)
                            }
                          />
                          {touched.ApellidoPaterno &&
                            errors.ApellidoPaterno && (
                              <div>{errors.ApellidoPahterno}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Apelido Materno</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.ApellidoMaterno}
                            onChange={(e) =>
                              setFieldValue("ApellidoMaterno", e.target.value)
                            }
                          />
                          {touched.ApellidoMaterno &&
                            errors.ApellidoMaterno && (
                              <div>{errors.ApellidoMaterno}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">CURP</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.CURP}
                            onChange={(e) =>
                              setFieldValue("CURP", e.target.value)
                            }
                          />
                          {touched.CURP1 && errors.CURP && (
                            <div>{errors.CURP}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">RFC</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.RFC}
                            onChange={(e) =>
                              setFieldValue("RFC", e.target.value)
                            }
                          />
                          {touched.RFC && errors.RFC && <div>{errors.RFC}</div>}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Ocupación Actual</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Ocupacion}
                            onChange={(e) =>
                              setFieldValue("Ocupacion", e.target.value)
                            }
                          />
                          {touched.Ocupacion && errors.Ocupacion && (
                            <div>{errors.Ocupacion}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Fecha de Nacimiento
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.FechadeNacimiento}
                            onChange={(e) =>
                              setFieldValue("FechadeNacimiento", e.target.value)
                            }
                          />
                          {touched.FechadeNacimiento &&
                            errors.FechadeNacimiento && (
                              <div>{errors.FechadeNacimiento}</div>
                            )}
                        </div>

                        <div className="form-outline col-lg-4">
                          <label className="form-label">Sexo</label>
                          <Select options={options} />
                          {touched.Sexo && errors.Sexo && (
                            <div>{errors.Sexo}</div>
                          )}
                        </div>

                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            LugardeResidencia
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.LugardeResidencia}
                            onChange={(e) =>
                              setFieldValue("LugardeResidencia", e.target.value)
                            }
                          />
                          {touched.LugardeResidencia &&
                            errors.LugardeResidencia && (
                              <div>{errors.LugardeResidencia}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Cancer}
                            onChange={(e) =>
                              setFieldValue("Cancer", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Cáncer
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Diabetes}
                            onChange={(e) =>
                              setFieldValue("Diabetes", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Diabetes
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.EnfermedadesdelCorazon}
                            onChange={(e) =>
                              setFieldValue(
                                "EnfermedadesdelCorazon",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Enfermedades del Corazón
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.PresionArterialAlta}
                            onChange={(e) =>
                              setFieldValue(
                                "PresionArterialAlta",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;PresionArterialAlta
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.EnfermedadesdelosRinones}
                            onChange={(e) =>
                              setFieldValue(
                                "EnfermedadesdelosRinones",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Enfermedades de los Riñones{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.EnfermedadesCerebrovasculares}
                            onChange={(e) =>
                              setFieldValue(
                                "EnfermedadesCerebrovasculares",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Enfermedades Cerebrovasculares
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Mentales}
                            onChange={(e) =>
                              setFieldValue("Mentales", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Mentales
                          </label>
                        </div>

                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Suicidio}
                            onChange={(e) =>
                              setFieldValue("Suicidio", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Suicidio
                          </label>
                        </div>

                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Otras - Especificar
                          </label>
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue("Otras", e.target.value)
                            }
                          >
                            {values.Otras}
                          </textarea>
                          {touched.Otras && errors.Otras && (
                            <div>{errors.Otras}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Se encuentra con vida el Padre?{" "}
                          </label>
                          <Select options={opciones} />
                          {touched.convidaelPadre && errors.convidaelPadre && (
                            <div>{errors.convidaelPadre}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Edad Padre</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadPadre}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.EdadPadre && errors.EdadPadre && (
                            <div>{errors.EdadPadre}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Estado de Salud Padre{" "}
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={values.EstadodeSaludPadre}
                            onChange={(e) =>
                              setFieldValue(
                                "EstadodeSaludPadre",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.EstadodeSaludPadre &&
                            errors.EstadodeSaludPadre && (
                              <div>{errors.EstadodeSaludPadre}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Edad Padre a su Muerte
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadPadreasuMuerte}
                            onChange={(e) =>
                              setFieldValue(
                                "EdadPadreasuMuerte",
                                e.target.value
                              )
                            }
                          />
                          {touched.EdadPadreasuMuerte &&
                            errors.EdadPadreasuMuerte && (
                              <div>{errors.EdadPadreasuMuerte}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Causa Muerte Padre
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={values.CausaMuertePadre}
                            onChange={(e) =>
                              setFieldValue("CausaMuertePadre", e.target.value)
                            }
                          ></textarea>
                          {touched.CausaMuertePadre &&
                            errors.CausaMuertePadre && (
                              <div>{errors.CausaMuertePadre}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Se encuentra con vida la Madre?
                          </label>
                          <Select options={opciones} />
                          {touched.convidalaMadre && errors.convidalaMadre && (
                            <div>{errors.convidalaMadre}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Edad Madre</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadMadre}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.EdadMadre && errors.EdadMadre && (
                            <div>{errors.EdadMadre}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Estado de Salud Madre{" "}
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            value={values.EstadodeSaludMadre}
                            onChange={(e) =>
                              setFieldValue(
                                "EstadodeSaludMadre",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.EstadodeSaludMadre &&
                            errors.EstadodeSaludMadre && (
                              <div>{errors.EstadodeSaludMadre}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Edad Madre a su Muerte
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadMadreasuMuerte}
                            onChange={(e) =>
                              setFieldValue(
                                "EdadMadreasuMuerte",
                                e.target.value
                              )
                            }
                          />
                          {touched.EdadMadreasuMuerte &&
                            errors.EdadMadreasuMuerte && (
                              <div>{errors.EdadMadreasuMuerte}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Causa Muerte Madre
                          </label>
                          <textarea
                            className="form-control"
                            value={values.CausaMuerteMadre}
                            onChange={(e) =>
                              setFieldValue("CausaMuerteMadre", e.target.value)
                            }
                          ></textarea>
                          {touched.CausaMuerteMadre &&
                            errors.CausaMuerteMadre && (
                              <div>{errors.CausaMuerteMadre}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Número de Hermanos Vivos
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NumerodeHermanosVivos}
                            onChange={(e) =>
                              setFieldValue(
                                "NumerodeHermanosVivos",
                                e.target.value
                              )
                            }
                          />
                          {touched.NumerodeHermanosVivos &&
                            errors.NumerodeHermanosVivos && (
                              <div>{errors.NumerodeHermanosVivos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Estado de Salud Hermanos{" "}
                          </label>
                          <textarea
                            className="form-control"
                            value={values.EstadodeSaludHermanos}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          ></textarea>
                          {touched.EstadodeSaludHermanos &&
                            errors.EstadodeSaludHermanos && (
                              <div>{errors.EstadodeSaludHermanos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Número de Hermanos Muertos
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NumerodeHermanosMuertos}
                            onChange={(e) =>
                              setFieldValue(
                                "NumerodeHermanosMuertos",
                                e.target.value
                              )
                            }
                          />
                          {touched.NumerodeHermanosMuertos &&
                            errors.NumerodeHermanosMuertos && (
                              <div>{errors.NumerodeHermanosMuertos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Causa Muerte Hermanos
                          </label>
                          <textarea
                            className="form-control"
                            value={values.CausaMuerteHermanos}
                            onChange={(e) =>
                              setFieldValue(
                                "CausaMuerteHermanos",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.CausaMuerteHermanos &&
                            errors.CausaMuerteHermanos && (
                              <div>{errors.CausaMuerteHermanos}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Se encuentra con vida Cónyuge?
                          </label>
                          <Select
                            options={options}
                            value={values.convidaConyuge}
                          />
                          {touched.convidaConyuge && errors.convidaConyuge && (
                            <div>{errors.convidaConyuge}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Edad Cónyuge</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadConyuge}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.EdadConyuge && errors.EdadConyuge && (
                            <div>{errors.EdadConyuge}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Estado de Salud Cónyuge
                          </label>
                          <textarea
                            className="form-control"
                            value={values.EstadodeSaludConyuge}
                            onChange={(e) =>
                              setFieldValue(
                                "EstadodeSaludConyuge",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.EstadodeSaludConyuge &&
                            errors.EstadodeSaludConyuge && (
                              <div>{errors.EstadodeSaludConyuge}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Edad Cónyuge a su Muerte{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.EdadConyugeasuMuerte}
                            onChange={(e) =>
                              setFieldValue(
                                "EdadConyugeasuMuerte",
                                e.target.value
                              )
                            }
                          />
                          {touched.EdadConyugeasuMuerte &&
                            errors.EdadConyugeasuMuerte && (
                              <div>{errors.EdadConyugeasuMuerte}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Número de Hijos Vivos
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NumerodeHijosVivos}
                            onChange={(e) =>
                              setFieldValue(
                                "NumerodeHijosVivos",
                                e.target.value
                              )
                            }
                          />
                          {touched.NumerodeHijosVivos &&
                            errors.NumerodeHijosVivos && (
                              <div>{errors.NumerodeHijosVivos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Estado de Salud Hijos{" "}
                          </label>
                          <textarea
                            className="form-control"
                            value={values.EstadodeSaludHijos}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          ></textarea>
                          {touched.EstadodeSaludHijos &&
                            errors.EstadodeSaludHijos && (
                              <div>{errors.EstadodeSaludHijos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Número de Hijos Muertos
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NumerodeHijosMuertos}
                            onChange={(e) =>
                              setFieldValue(
                                "NumerodeHijosMuertos",
                                e.target.value
                              )
                            }
                          />
                          {touched.NumerodeHijosMuertos &&
                            errors.NumerodeHijosMuertos && (
                              <div>{errors.NumerodeHijosMuertos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Causa de Muerte Hijos
                          </label>
                          <textarea
                            className="form-control"
                            value={values.CausadeMuerteHijos}
                            onChange={(e) =>
                              setFieldValue(
                                "CausadeMuerteHijos",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.CausadeMuerteHijos &&
                            errors.CausadeMuerteHijos && (
                              <div>{errors.CausadeMuerteHijos}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Fuma Actualmente?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.FumaActualmente}
                            onChange={(e) =>
                              setFieldValue("FumaActualmente", e.target.value)
                            }
                          />
                          {touched.FumaActualmente &&
                            errors.FumaActualmente && (
                              <div>{errors.FumaActualmente}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Fumaba anteriormente?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Fumabaanteriormente}
                          />
                          {touched.Fumabaanteriormente &&
                            errors.Fumabaanteriormente && (
                              <div>{errors.Fumabaanteriormente}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿En qué año empezó a fumar?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Enqueanoempezoafumar}
                            onChange={(e) =>
                              setFieldValue(
                                "Enqueanoempezoafumar",
                                e.target.value
                              )
                            }
                          />
                          {touched.Enqueanoempezoafumar &&
                            errors.Enqueanoempezoafumar && (
                              <div>{errors.Enqueanoempezoafumar}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Cigarros diarios que fuma o fumaba
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Cigarrosdiariosquefumaofumaba}
                            onChange={(e) =>
                              setFieldValue(
                                "Cigarrosdiariosquefumaofumaba",
                                e.target.value
                              )
                            }
                          />
                          {touched.Cigarrosdiariosquefumaofumaba &&
                            errors.Cigarrosdiariosquefumaofumaba && (
                              <div>{errors.Cigarrosdiariosquefumaofumaba}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿En qué año dejó de fumar?{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Enqueanodejodefumar}
                            onChange={(e) =>
                              setFieldValue(
                                "Enqueanodejodefumar",
                                e.target.value
                              )
                            }
                          />
                          {touched.Enqueanodejodefumar &&
                            errors.Enqueanodejodefumar && (
                              <div>{errors.Enqueanodejodefumar}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Por qué razón?</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Porquerazon}
                            onChange={(e) =>
                              setFieldValue("Porquerazon", e.target.value)
                            }
                          />
                          {touched.Porquerazon && errors.Porquerazon && (
                            <div>{errors.Porquerazon}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ingiere Bebidas Alcoholicas?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.IngiereBebidasAlcoholicas}
                          />
                          {touched.IngiereBebidasAlcoholicas &&
                            errors.IngiereBebidasAlcoholicas && (
                              <div>{errors.IngiereBebidasAlcoholicas}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Desde cuándo? </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Desdecuando}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.Desdecuando && errors.Desdecuando && (
                            <div>{errors.Desdecuando}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Clase</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Clase}
                            onChange={(e) =>
                              setFieldValue("Clase", e.target.value)
                            }
                          />
                          {touched.Clase && errors.Clase && (
                            <div>{errors.Clase}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Frecuencia </label>
                          <Select
                            options={opcionesFrecuencia}
                            value={values.Frecuencia}
                          />
                          {touched.Frecuencia && errors.Frecuencia && (
                            <div>{errors.Frecuencia}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Otros </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Otros}
                            onChange={(e) =>
                              setFieldValue("Otros", e.target.value)
                            }
                          />
                          {touched.Otros && errors.Otros && (
                            <div>{errors.Otros}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Cantidad en mi</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Cantidadenmi}
                            onChange={(e) =>
                              setFieldValue("Cantidadenmi", e.target.value)
                            }
                          />
                          {touched.Cantidadenmi && errors.Cantidadenmi && (
                            <div>{errors.Cantidadenmi}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Llega al estado de embriaguez?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Llegaalestadodeembriaguez}
                          />
                          {touched.Llegaalestadodeembriaguez &&
                            errors.Llegaalestadodeembriaguez && (
                              <div>{errors.Llegaalestadodeembriaguez}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha tenido accidentes por el alcohol?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Hatenidoaccidentesporelalcohol}
                          />
                          {touched.Hatenidoaccidentesporelalcohol &&
                            errors.Hatenidoaccidentesporelalcohol && (
                              <div>{errors.Hatenidoaccidentesporelalcohol}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha tenido accidentes por el alcohol?{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Hatenidoaccidentesporelalcohol}
                            onChange={(e) =>
                              setFieldValue(
                                "Hatenidoaccidentesporelalcohol",
                                e.target.value
                              )
                            }
                          />
                          {touched.Hatenidoaccidentesporelalcohol &&
                            errors.Hatenidoaccidentesporelalcohol && (
                              <div>{errors.Hatenidoaccidentesporelalcohol}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Si dejó de beber señale fecha y causa{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Sidejodebebersenalefechaycausa}
                            onChange={(e) =>
                              setFieldValue(
                                "Sidejodebebersenalefechaycausa",
                                e.target.value
                              )
                            }
                          />
                          {touched.Sidejodebebersenalefechaycausa &&
                            errors.Sidejodebebersenalefechaycausa && (
                              <div>{errors.Sidejodebebersenalefechaycausa}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Cuánto bebia antes clase-frecuencia-cantidad
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.Cuantobebiaantesclasefrecuenciacantidad
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Cuantobebiaantesclasefrecuenciacantidad",
                                e.target.value
                              )
                            }
                          />
                          {touched.Cuantobebiaantesclasefrecuenciacantidad &&
                            errors.Cuantobebiaantesclasefrecuenciacantidad && (
                              <div>
                                {errors.Cuantobebiaantesclasefrecuenciacantidad}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha recibido tratamiento por consumo de alcohol?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Harecibidotratamientoporconsumodealcohol
                            }
                          />
                          {touched.Harecibidotratamientoporconsumodealcohol &&
                            errors.Harecibidotratamientoporconsumodealcohol && (
                              <div>
                                {
                                  errors.Harecibidotratamientoporconsumodealcohol
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Pertenece a alguna asociación de alcoholicos?{" "}
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Perteneceaalgunaasociaciondealcoholicos
                            }
                          />
                          {touched.Perteneceaalgunaasociaciondealcoholicos &&
                            errors.Perteneceaalgunaasociaciondealcoholicos && (
                              <div>
                                {errors.Perteneceaalgunaasociaciondealcoholicos}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha utilizado drogas psicoactivas?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Hautilizadodrogaspsicoactivas}
                          />

                          {touched.Hautilizadodrogaspsicoactivas &&
                            errors.Hautilizadodrogaspsicoactivas && (
                              <div>{errors.Hautilizadodrogaspsicoactivas}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            En caso afirmativo señale tipo de droga{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Encasoafirmativoseñaletipodedroga}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.Encasoafirmativoseñaletipodedroga &&
                            errors.Encasoafirmativoseñaletipodedroga && (
                              <div>
                                {errors.Encasoafirmativoseñaletipodedroga}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            En caso afirmativo señale frecuencia
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Encasoafirmativosenalefrecuencia}
                            onChange={(e) =>
                              setFieldValue(
                                "Encasoafirmativosenalefrecuencia",
                                e.target.value
                              )
                            }
                          />
                          {touched.Encasoafirmativosenalefrecuencia &&
                            errors.Encasoafirmativosenalefrecuencia && (
                              <div>
                                {errors.Encasoafirmativosenalefrecuencia}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            En caso afirmativo senale ultima ocasion{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Encasoafirmativosenaleultimaocasion}
                            onChange={(e) =>
                              setFieldValue(
                                "Encasoafirmativosenaleultimaocasion",
                                e.target.value
                              )
                            }
                          />
                          {touched.Encasoafirmativosenaleultimaocasion &&
                            errors.Encasoafirmativosenaleultimaocasion && (
                              <div>
                                {errors.Encasoafirmativosenaleultimaocasion}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha recibido tratamiento por consumo de drogas?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Harecibidotratamientoporconsumodedrogas
                            }
                          />

                          {touched.Harecibidotratamientoporconsumodedrogas &&
                            errors.Harecibidotratamientoporconsumodedrogas && (
                              <div>
                                {errors.Harecibidotratamientoporconsumodedrogas}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Pertenece a alguna asociación de drogadicción?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Perteneceaalgunaasociaciondedrogadiccion
                            }
                          />
                          {touched.Perteneceaalgunaasociaciondedrogadiccion &&
                            errors.Perteneceaalgunaasociaciondedrogadiccion && (
                              <div>
                                {
                                  errors.Perteneceaalgunaasociaciondedrogadiccion
                                }
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha variado su peso en el último año?
                          </label>
                          <Select
                            options={options}
                            value={values.Havariadosupesoenelultimoano}
                          />
                          {touched.Havariadosupesoenelultimoano &&
                            errors.Havariadosupesoenelultimoano && (
                              <div>{errors.Havariadosupesoenelultimoano}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Kgs aumentados</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Kgsaumentados}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          {touched.Kgsaumentados && errors.Kgsaumentados && (
                            <div>{errors.Kgsaumentados}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Kgs disminuidos</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Kgsdisminuidos}
                            onChange={(e) =>
                              setFieldValue("Kgsdisminuidos", e.target.value)
                            }
                          />
                          {touched.Kgsdisminuidos && errors.Kgsdisminuidos && (
                            <div>{errors.Kgsdisminuidos}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Causa</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Causa}
                            onChange={(e) =>
                              setFieldValue("Causa", e.target.value)
                            }
                          />
                          {touched.Causa && errors.Causa && (
                            <div>{errors.Causa}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Realiza regularmente alguna actividad física?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Realizaregularmentealgunaactividadfisica
                            }
                          />

                          {touched.Realizaregularmentealgunaactividadfisica &&
                            errors.Realizaregularmentealgunaactividadfisica && (
                              <div>
                                {
                                  errors.Realizaregularmentealgunaactividadfisica
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Tipo de ejercicio
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Tipodeejercicio}
                            onChange={(e) =>
                              setFieldValue("Tipodeejercicio", e.target.value)
                            }
                          />
                          {touched.Tipodeejercicio &&
                            errors.Tipodeejercicio && (
                              <div>{errors.Tipodeejercicio}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Padece actualmente alguna enfermedad?
                          </label>
                          <Select
                            options={options}
                            value={values.Padeceactualmentealgunaenfermedad}
                          />
                          {touched.Padeceactualmentealgunaenfermedad &&
                            errors.Padeceactualmentealgunaenfermedad && (
                              <div>
                                {errors.Padeceactualmentealgunaenfermedad}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿En los últimos 2 años ha consultado algún médico?{" "}
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Enlosultimos2anoshaconsultadoalgunmedico
                            }
                          />

                          {touched.Enlosultimos2anoshaconsultadoalgunmedico &&
                            errors.Enlosultimos2anoshaconsultadoalgunmedico && (
                              <div>
                                {
                                  errors.Enlosultimos2anoshaconsultadoalgunmedico
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Está sujeto a cualquier tratamiento médico?
                          </label>
                          <Select
                            options={options}
                            value={values.Estasujetoacualquiertratamientomedico}
                          />

                          {touched.Estasujetoacualquiertratamientomedico &&
                            errors.Estasujetoacualquiertratamientomedico && (
                              <div>
                                {errors.Estasujetoacualquiertratamientomedico}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha recibido transfusiones de sangre?
                          </label>
                          <Select
                            options={options}
                            value={values.Harecibidotransfusionesdesangre}
                          />

                          {touched.Harecibidotransfusionesdesangre &&
                            errors.Harecibidotransfusionesdesangre && (
                              <div>
                                {errors.Harecibidotransfusionesdesangre}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha consultado por enfermedad transmisión sexual?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Haconsultadoporenfermedadtransmisionsexual
                            }
                          />

                          {touched.Haconsultadoporenfermedadtransmisionsexual &&
                            errors.Haconsultadoporenfermedadtransmisionsexual && (
                              <div>
                                {
                                  errors.Haconsultadoporenfermedadtransmisionsexual
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Le han dicho que tiene SIDA?
                          </label>
                          <Select
                            options={options}
                            value={values.LehandichoquetieneSIDA}
                          />

                          {touched.LehandichoquetieneSIDA &&
                            errors.LehandichoquetieneSIDA && (
                              <div>{errors.LehandichoquetieneSIDA}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha recibido tratamiento las causas anteriores?
                          </label>
                          <Select
                            options={options}
                            value={
                              values.Harecibidotratamientolascausasanteriores
                            }
                          />

                          {touched.Harecibidotratamientolascausasanteriores &&
                            errors.Harecibidotratamientolascausasanteriores && (
                              <div>
                                {
                                  errors.Harecibidotratamientolascausasanteriores
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            En caso de cualquier respuesta positiva describa{" "}
                          </label>
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue("Otras", e.target.value)
                            }
                          >
                            {values.Encasodecualquierrespuestapositivadescriba1}
                          </textarea>
                          {touched.Encasodecualquierrespuestapositivadescriba1 &&
                            errors.Encasodecualquierrespuestapositivadescriba1 && (
                              <div>
                                {
                                  errors.Encasodecualquierrespuestapositivadescriba1
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Tipo de sangre</label>
                          <Select
                            options={tipodeSangre}
                            value={values.tipodeSangre}
                          />
                          {touched.Tipodesangre && errors.Tipodesangre && (
                            <div>{errors.Tipodesangre}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Algún soplo del corazón?
                          </label>
                          <Select
                            options={options}
                            value={values.soplodelcorazon}
                          />

                          {touched.soplodelcorazon &&
                            errors.soplodelcorazon && (
                              <div>{errors.soplodelcorazon}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Dolor de pecho o en el torax?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Dolordepechooeneltorax}
                          />

                          {touched.Dolordepechooeneltorax &&
                            errors.Dolordepechooeneltorax && (
                              <div>{errors.Dolordepechooeneltorax}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Infarto en el corazón?
                          </label>
                          <Select
                            options={options}
                            value={values.Infartoenelcorazon}
                          />

                          {touched.Infartoenelcorazon &&
                            errors.Infartoenelcorazon && (
                              <div>{errors.Infartoenelcorazon}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Alguna otra enfermedad de corazón?
                          </label>
                          <Select
                            options={options}
                            value={values.Algunaotraenfermedaddecorazon}
                          />

                          {touched.Algunaotraenfermedaddecorazon &&
                            errors.Algunaotraenfermedaddecorazon && (
                              <div>{errors.Algunaotraenfermedaddecorazon}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedades de circulación?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadesdecirculacion}
                          />

                          {touched.Enfermedadesdecirculacion &&
                            errors.Enfermedadesdecirculacion && (
                              <div>{errors.Enfermedadesdecirculacion}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Presión arterial alta?
                          </label>
                          <Select
                            options={options}
                            value={values.Presionarterialalta}
                          />

                          {touched.Presionarterialalta &&
                            errors.Presionarterialalta && (
                              <div>{errors.Presionarterialalta}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Bronquitis crónica?
                          </label>
                          <Select
                            options={options}
                            value={values.Bronquitiscronica}
                          />

                          {touched.Bronquitiscronica &&
                            errors.Bronquitiscronica && (
                              <div>{errors.Bronquitiscronica}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Asma?</label>
                          <Select options={options} value={values.Asma} />

                          {touched.Asma && errors.Asma && (
                            <div>{errors.Asma}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Tuberculosis?</label>
                          <Select
                            options={options}
                            value={values.Tuberculosis}
                          />

                          {touched.Tuberculosis && errors.Tuberculosis && (
                            <div>{errors.Tuberculosis}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Cáncer?</label>
                          <Select options={options} value={values.Cancer} />

                          {touched.Cancer && errors.Cancer && (
                            <div>{errors.Cancer}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Úlcera del duodeno?
                          </label>
                          <Select
                            options={options}
                            value={values.Ulceradelduodeno}
                          />

                          {touched.Ulceradelduodeno &&
                            errors.Ulceradelduodeno && (
                              <div>{errors.Ulceradelduodeno}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Úlcera del estómago?
                          </label>
                          <Select
                            options={options}
                            value={values.Ulceradelestomago}
                          />

                          {touched.Ulceradelestomago &&
                            errors.Ulceradelestomago && (
                              <div>{errors.Ulceradelestomago}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedad del hígado?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedaddelhigado}
                          />

                          {touched.Enfermedaddelhigado &&
                            errors.Enfermedaddelhigado && (
                              <div>{errors.Enfermedaddelhigado}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedades de la vesícula biliar ?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadesdelavesiculabiliar}
                          />

                          {touched.Enfermedadesdelavesiculabiliar &&
                            errors.Enfermedadesdelavesiculabiliar && (
                              <div>{errors.Enfermedadesdelavesiculabiliar}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Enfermedades del intestino colon o recto{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadesdelintestinocolonorecto}
                          />

                          {touched.Enfermedadesdelintestinocolonorecto &&
                            errors.Enfermedadesdelintestinocolonorecto && (
                              <div>
                                {errors.Enfermedadesdelintestinocolonorecto}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedades de los riñones?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadeselosrinones}
                          />

                          {touched.Enfermedadeselosrinones &&
                            errors.Enfermedadeselosrinones && (
                              <div>{errors.Enfermedadeselosrinones}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedades de la vejiga?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadesdelavejiga}
                          />

                          {touched.Enfermedadesdelavejiga &&
                            errors.Enfermedadesdelavejiga && (
                              <div>{errors.Enfermedadesdelavejiga}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Diabetes?</label>
                          <Select options={options} value={values.Diabetes} />

                          {touched.Diabetes && errors.Diabetes && (
                            <div>{errors.Diabetes}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Algún tumor? </label>
                          <Select options={options} value={values.Alguntumor} />

                          {touched.Alguntumor && errors.Alguntumor && (
                            <div>{errors.Alguntumor}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">¿Paralisis?</label>
                          <Select options={options} value={values.Paralisis} />

                          {touched.Paralisis && errors.Paralisis && (
                            <div>{errors.Paralisis}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Perdida de conocimiento o convulsiones?
                          </label>
                          <Select
                            options={options}
                            value={values.Perdidadeconocimientooconvulsiones}
                          />

                          {touched.Perdidadeconocimientooconvulsiones &&
                            errors.Perdidadeconocimientooconvulsiones && (
                              <div>
                                {errors.Perdidadeconocimientooconvulsiones}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Astemia adinamia y anorexia
                          </label>
                          <Select
                            options={options}
                            value={values.Astemiaadinamiayanorexia}
                          />

                          {touched.Astemiaadinamiayanorexia &&
                            errors.Astemiaadinamiayanorexia && (
                              <div>{errors.Astemiaadinamiayanorexia}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Cuadros diarreicos frecuentes?{" "}
                          </label>
                          <Select
                            options={options}
                            value={values.Cuadrosdiarreicosfrecuentes}
                          />

                          {touched.Cuadrosdiarreicosfrecuentes &&
                            errors.Cuadrosdiarreicosfrecuentes && (
                              <div>{errors.Cuadrosdiarreicosfrecuentes}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Hipertrofia ganglionar?
                          </label>
                          <Select
                            options={options}
                            value={values.Hipertrofiaganglionar}
                          />

                          {touched.Hipertrofiaganglionar &&
                            errors.Hipertrofiaganglionar && (
                              <div>{errors.Hipertrofiaganglionar}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Diaforisis nocturna?
                          </label>
                          <Select
                            options={options}
                            value={values.Diaforisisnocturna}
                          />

                          {touched.Diaforisisnocturna &&
                            errors.Diaforisisnocturna && (
                              <div>{errors.Diaforisisnocturna}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Enfermedades de los huesos?
                          </label>
                          <Select
                            options={options}
                            value={values.Enfermedadesdeloshuesos}
                          />

                          {touched.Enfermedadesdeloshuesos &&
                            errors.Enfermedadesdeloshuesos && (
                              <div>{errors.Enfermedadesdeloshuesos}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Transtornos nerviosos o mentales?
                          </label>
                          <Select
                            options={options}
                            value={values.Transtornosnerviososomentales}
                          />

                          {touched.Transtornosnerviososomentales &&
                            errors.Transtornosnerviososomentales && (
                              <div>{errors.Transtornosnerviososomentales}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Alguna intervención quirúrgica?
                          </label>
                          <Select
                            options={options}
                            value={values.Algunaintervencionquirurgica}
                          />

                          {touched.Algunaintervencionquirurgica &&
                            errors.Algunaintervencionquirurgica && (
                              <div>{errors.Algunaintervencionquirurgica}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Alguna otra enfermedad?
                          </label>
                          <Select
                            options={options}
                            value={values.Algunaotraenfermedad}
                          />

                          {touched.Algunaotraenfermedad &&
                            errors.Algunaotraenfermedad && (
                              <div>{errors.Algunaotraenfermedad}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Describa en caso de poner Si
                          </label>
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue("Describa", e.target.value)
                            }
                          >
                            {values.Describa}
                          </textarea>
                          {touched.Describa && errors.Describa && (
                            <div>{errors.Describa}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Electrocardiogramas}
                            onChange={(e) =>
                              setFieldValue(
                                "Electrocardiogramas",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Electrocardiogramas
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Analisisopruebasdelaboratorio}
                            onChange={(e) =>
                              setFieldValue(
                                "Analisisopruebasdelaboratorio",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Análisis o pruebas de laboratorio
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Radiografias}
                            onChange={(e) =>
                              setFieldValue("Radiografias", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Radiografías{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.PruebaparadetectarSIDA}
                            onChange={(e) =>
                              setFieldValue(
                                "PruebaparadetectarSIDA",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Prueba para detectar SIDA
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Ha estado hospitalizado alguna vez?
                          </label>
                          <Select
                            options={opciones}
                            value={values.Haestadohospitalizadoalgunavez}
                          />
                          {touched.Haestadohospitalizadoalgunavez &&
                            errors.Haestadohospitalizadoalgunavez && (
                              <div>{errors.Haestadohospitalizadoalgunavez}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Sitio y Fecha</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.SitioyFecha}
                            onChange={(e) =>
                              setFieldValue("SitioyFecha", e.target.value)
                            }
                          />
                          {touched.SitioyFecha && errors.SitioyFecha && (
                            <div>{errors.SitioyFecha}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            En caso de cualquier respuesta afirmativa describa
                          </label>
                          <textarea
                            className="form-control"
                            value={
                              values.Encasodecualquierrespuestaafirmativadescriba
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Encasodecualquierrespuestaafirmativadescriba",
                                e.target.value
                              )
                            }
                          ></textarea>
                          {touched.Encasodecualquierrespuestaafirmativadescriba &&
                            errors.Encasodecualquierrespuestaafirmativadescriba && (
                              <div>
                                {
                                  errors.Encasodecualquierrespuestaafirmativadescriba
                                }
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Endermedadesdeglandulasmamarias}
                            onChange={(e) =>
                              setFieldValue(
                                "Endermedadesdeglandulasmamarias",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Endermedades de glandulas mamarias
                          </label>
                          {touched.Endermedadesdeglandulasmamarias &&
                            errors.Endermedadesdeglandulasmamarias && (
                              <div>
                                {errors.Endermedadesdeglandulasmamarias}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.EnfermedadesdeUtero}
                            onChange={(e) => setFieldValue("v", e.target.value)}
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Enfermedades de Utero
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Estaembarazada}
                            onChange={(e) =>
                              setFieldValue("Estaembarazada", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Está embarazada
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Semanas de gestación en caso afirmativo
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Semanasdegestacionencasoafirmativo}
                            onChange={(e) =>
                              setFieldValue(
                                "Semanasdegestacionencasoafirmativo",
                                e.target.value
                              )
                            }
                          />
                          {touched.Semanasdegestacionencasoafirmativo &&
                            errors.Semanasdegestacionencasoafirmativo && (
                              <div>
                                {errors.Semanasdegestacionencasoafirmativo}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Fecha de última regla
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Fechadeultimaregla}
                            onChange={(e) =>
                              setFieldValue(
                                "Fechadeultimaregla",
                                e.target.value
                              )
                            }
                          />
                          {touched.Fechadeultimaregla &&
                            errors.Fechadeultimaregla && (
                              <div>{errors.Fechadeultimaregla}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.AntecedentesginecoobstetricosGESTA}
                            onChange={(e) =>
                              setFieldValue(
                                "AntecedentesginecoobstetricosGESTA",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Antecedentes gineco-obstétricos GESTA
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.AntecedentesginecoobstetricosPARA}
                            onChange={(e) =>
                              setFieldValue(
                                "AntecedentesginecoobstetricosPARA",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Antecedentes gineco-obstétricos PARA{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.AntecedentesginecoobstetricosABORTOS}
                            onChange={(e) =>
                              setFieldValue(
                                "AntecedentesginecoobstetricosABORTOS",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Antecedentes gineco-obstétricos ABORTOS
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.AntecedentesginecoobstetricosCESAREA}
                            onChange={(e) =>
                              setFieldValue(
                                "AntecedentesginecoobstetricosCESAREA",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Antecedentes gineco-obstétricos CESAREA{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Selehapracticadomastografia}
                            onChange={(e) =>
                              setFieldValue(
                                "Selehapracticadomastografia",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Se le ha practicado mastografía
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Motivos y Resultados{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.MotivosyResultados}
                            onChange={(e) =>
                              setFieldValue(
                                "MotivosyResultados",
                                e.target.value
                              )
                            }
                          />
                          {touched.MotivosyResultados &&
                            errors.MotivosyResultados && (
                              <div>{errors.MotivosyResultados}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.SelehapracticadoPapanicolau}
                            onChange={(e) =>
                              setFieldValue(
                                "SelehapracticadoPapanicolau",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Se le ha practicado Papanicolau
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Fecha Papanicolau
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.FechaPapanicolau}
                            onChange={(e) =>
                              setFieldValue("FechaPapanicolau", e.target.value)
                            }
                          />
                          {touched.FechaPapanicolau &&
                            errors.FechaPapanicolau && (
                              <div>{errors.FechaPapanicolau}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Resultado</label>
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue("Resultado", e.target.value)
                            }
                          >
                            {values.Resultado}
                          </textarea>
                          {touched.Resultado && errors.Resultado && (
                            <div>{errors.Resultado}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Estatura en cms.</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Estaturaencms}
                            onChange={(e) =>
                              setFieldValue("Estaturaencms", e.target.value)
                            }
                          />
                          {touched.Estaturaencms && errors.Estaturaencms && (
                            <div>{errors.Estaturaencms}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Peso en Kgs</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PesoenKgs}
                            onChange={(e) =>
                              setFieldValue("PesoenKgs", e.target.value)
                            }
                          />
                          {touched.PesoenKgs && errors.PesoenKgs && (
                            <div>{errors.PesoenKgs}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Lomidio}
                            onChange={(e) =>
                              setFieldValue("Lomidio", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;¿Lo midió?
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Lopeso}
                            onChange={(e) =>
                              setFieldValue("Lopeso", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;¿Lo pesó?
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Indice de Mása Corporal
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.IndicedeMasaCorporal}
                            onChange={(e) =>
                              setFieldValue(
                                "IndicedeMasaCorporal",
                                e.target.value
                              )
                            }
                          />
                          {touched.IndicedeMasaCorporal &&
                            errors.IndicedeMasaCorporal && (
                              <div>{errors.IndicedeMasaCorporal}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Glucosa Capliar</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.GlucosaCapliar}
                            onChange={(e) =>
                              setFieldValue("GlucosaCapliar", e.target.value)
                            }
                          />
                          {touched.GlucosaCapliar && errors.GlucosaCapliar && (
                            <div>{errors.GlucosaCapliar}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Perímetro en Torax Inspiración Plena
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PerimetroenToraxInspiracionPlena}
                            onChange={(e) =>
                              setFieldValue(
                                "PerimetroenToraxInspiracionPlena",
                                e.target.value
                              )
                            }
                          />
                          {touched.PerimetroenToraxInspiracionPlena &&
                            errors.PerimetroenToraxInspiracionPlena && (
                              <div>
                                {errors.PerimetroenToraxInspiracionPlena}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Perímetro en Torax espiración forzada
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PerimetroenToraxespiracionforzada}
                            onChange={(e) =>
                              setFieldValue(
                                "PerimetroenToraxespiracionforzada",
                                e.target.value
                              )
                            }
                          />
                          {touched.PerimetroenToraxespiracionforzada &&
                            errors.PerimetroenToraxespiracionforzada && (
                              <div>
                                {errors.PerimetroenToraxespiracionforzada}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Perímetro del abdomen a nivel ombligo
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Perimetrodelabdomenanivelombligo}
                            onChange={(e) =>
                              setFieldValue(
                                "Perimetrodelabdomenanivelombligo",
                                e.target.value
                              )
                            }
                          />
                          {touched.Perimetrodelabdomenanivelombligo &&
                            errors.Perimetrodelabdomenanivelombligo && (
                              <div>
                                {errors.Perimetrodelabdomenanivelombligo}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Pulso frecuencia cardiaca en minuto completo
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.Pulsofrecuenciacardiacaenminutocompleto
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Pulsofrecuenciacardiacaenminutocompleto",
                                e.target.value
                              )
                            }
                          />
                          {touched.Pulsofrecuenciacardiacaenminutocompleto &&
                            errors.Pulsofrecuenciacardiacaenminutocompleto && (
                              <div>
                                {errors.Pulsofrecuenciacardiacaenminutocompleto}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Es rítmico</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Esritmico}
                            onChange={(e) =>
                              setFieldValue(
                                "Selehapracticadomastografia",
                                e.target.value
                              )
                            }
                          />
                          {touched.Esritmico && errors.Esritmico && (
                            <div>{errors.Esritmico}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Número de extrasístoles por minuto
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Numerodeextrasistolesporminuto}
                            onChange={(e) =>
                              setFieldValue(
                                "Numerodeextrasistolesporminuto",
                                e.target.value
                              )
                            }
                          />
                          {touched.Numerodeextrasistolesporminuto &&
                            errors.Numerodeextrasistolesporminuto && (
                              <div>{errors.Numerodeextrasistolesporminuto}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Prueba de Esfuerzo - Frecuencia en minuto completo{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.PruebadeEsfuerzoFrecuenciaenminutocompleto
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "PruebadeEsfuerzoFrecuenciaenminutocompleto",
                                e.target.value
                              )
                            }
                          />
                          {touched.PruebadeEsfuerzoFrecuenciaenminutocompleto &&
                            errors.PruebadeEsfuerzoFrecuenciaenminutocompleto && (
                              <div>
                                {
                                  errors.PruebadeEsfuerzoFrecuenciaenminutocompleto
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Prueba de Esfuerzo - Es rítmico
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PruebadeEsfuerzoEsritmico}
                            onChange={(e) =>
                              setFieldValue(
                                "PruebadeEsfuerzoEsritmico",
                                e.target.value
                              )
                            }
                          />
                          {touched.PruebadeEsfuerzoEsritmico &&
                            errors.PruebadeEsfuerzoEsritmico && (
                              <div>{errors.PruebadeEsfuerzoEsritmico}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Prueba de Esfuerzo - Prueba de extrasoides por min
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.PruebadeEsfuerzoPruebadeextrasoidespormin
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "PruebadeEsfuerzoPruebadeextrasoidespormin",
                                e.target.value
                              )
                            }
                          />
                          {touched.PruebadeEsfuerzoPruebadeextrasoidespormin &&
                            errors.PruebadeEsfuerzoPruebadeextrasoidespormin && (
                              <div>
                                {
                                  errors.PruebadeEsfuerzoPruebadeextrasoidespormin
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Presión Arterial - Método Auscultatorio{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PresionArterialMetodoAuscultatorio}
                            onChange={(e) =>
                              setFieldValue(
                                "PresionArterialMetodoAuscultatorio",
                                e.target.value
                              )
                            }
                          />
                          {touched.PresionArterialMetodoAuscultatorio &&
                            errors.PresionArterialMetodoAuscultatorio && (
                              <div>
                                {errors.PresionArterialMetodoAuscultatorio}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Presión Arterial - diastólica
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.PresionArterialdiastólica}
                            onChange={(e) =>
                              setFieldValue(
                                "PresionArterialdiastólica",
                                e.target.value
                              )
                            }
                          />
                          {touched.PresionArterialdiastólica &&
                            errors.PresionArterialdiastólica && (
                              <div>{errors.PresionArterialdiastólica}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Nivel espacio intercostal en que palpa el corazón
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.Nivelespaciointercostalenquepalpaelcorazon
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Nivelespaciointercostalenquepalpaelcorazon",
                                e.target.value
                              )
                            }
                          />
                          {touched.Nivelespaciointercostalenquepalpaelcorazon &&
                            errors.Nivelespaciointercostalenquepalpaelcorazon && (
                              <div>
                                {
                                  errors.Nivelespaciointercostalenquepalpaelcorazon
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Nivel espacio intercostal en que palpa el corazón
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.Nivelespaxiointercostalenquepalpaelcorazon
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Nivelespaxiointercostalenquepalpaelcorazon",
                                e.target.value
                              )
                            }
                          />
                          {touched.Nivelespaxiointercostalenquepalpaelcorazon &&
                            errors.Nivelespaxiointercostalenquepalpaelcorazon && (
                              <div>
                                {
                                  errors.Nivelespaxiointercostalenquepalpaelcorazon
                                }
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿Existe algún dato anormal de palpitación?{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Existealgundatoanormaldepalpitacion}
                            onChange={(e) =>
                              setFieldValue(
                                "Existealgundatoanormaldepalpitacion",
                                e.target.value
                              )
                            }
                          />
                          {touched.Existealgundatoanormaldepalpitacion &&
                            errors.Existealgundatoanormaldepalpitacion && (
                              <div>
                                {errors.Existealgundatoanormaldepalpitacion}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            ¿La auscultación en focos clásicos es normal?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Laauscultacionenfocosclasicosesnormal}
                            onChange={(e) =>
                              setFieldValue(
                                "Laauscultacionenfocosclasicosesnormal",
                                e.target.value
                              )
                            }
                          />
                          {touched.Laauscultacionenfocosclasicosesnormal &&
                            errors.Laauscultacionenfocosclasicosesnormal && (
                              <div>
                                {errors.Laauscultacionenfocosclasicosesnormal}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.CrecimientoCardiaco}
                            onChange={(e) =>
                              setFieldValue(
                                "CrecimientoCardiaco",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Crecimiento Cardiaco
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Disnea}
                            onChange={(e) =>
                              setFieldValue("Disnea", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Disnea
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Edema}
                            onChange={(e) =>
                              setFieldValue("Edema", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Edema
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Constante </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Constante}
                            onChange={(e) =>
                              setFieldValue("Constante", e.target.value)
                            }
                          />
                          {touched.Constante && errors.Constante && (
                            <div>{errors.Constante}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Inconstante </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Inconstante}
                            onChange={(e) =>
                              setFieldValue("Inconstante", e.target.value)
                            }
                          />
                          {touched.Inconstante && errors.Inconstante && (
                            <div>{errors.Inconstante}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Transmitido </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Transmitido}
                            onChange={(e) =>
                              setFieldValue("Transmitido", e.target.value)
                            }
                          />
                          {touched.Transmitido && errors.Transmitido && (
                            <div>{errors.Transmitido}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Localizado </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Localizado}
                            onChange={(e) =>
                              setFieldValue("Localizado", e.target.value)
                            }
                          />
                          {touched.Localizado && errors.Localizado && (
                            <div>{errors.Localizado}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Sistólico </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Sistolico}
                            onChange={(e) =>
                              setFieldValue("Sistolico", e.target.value)
                            }
                          />
                          {touched.Sistolico && errors.Sistolico && (
                            <div>{errors.Sistolico}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Presistólico </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Presistolico}
                            onChange={(e) =>
                              setFieldValue("Presistolico", e.target.value)
                            }
                          />
                          {touched.Presistolico && errors.Presistolico && (
                            <div>{errors.Presistolico}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Diastólico </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Diastolico}
                            onChange={(e) =>
                              setFieldValue("Diastolico", e.target.value)
                            }
                          />
                          {touched.Diastolico && errors.Diastolico && (
                            <div>{errors.Diastolico}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Suave gr 1-2</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Suavegr12}
                            onChange={(e) =>
                              setFieldValue("Suavegr12", e.target.value)
                            }
                          />
                          {touched.Suavegr12 && errors.Suavegr12 && (
                            <div>{errors.Suavegr12}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Moderadogr34</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Moderadogr34}
                            onChange={(e) =>
                              setFieldValue("Moderadogr34", e.target.value)
                            }
                          />
                          {touched.Moderadogr34 && errors.Moderadogr34 && (
                            <div>{errors.Moderadogr34}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Fuerte gr 5-6</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Fuertegr56}
                            onChange={(e) =>
                              setFieldValue("Fuertegr56", e.target.value)
                            }
                          />
                          {touched.Fuertegr56 && errors.Fuertegr56 && (
                            <div>{errors.Fuertegr56}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Después del ejercicio se incrementa
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Despuesdelejercicioseincrementa}
                            onChange={(e) =>
                              setFieldValue(
                                "Despuesdelejercicioseincrementa",
                                e.target.value
                              )
                            }
                          />
                          {touched.Despuesdelejercicioseincrementa &&
                            errors.Despuesdelejercicioseincrementa && (
                              <div>
                                {errors.Despuesdelejercicioseincrementa}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Después del ejercicio se desaparece{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Despuesdelejerciciosedesaparece}
                            onChange={(e) =>
                              setFieldValue(
                                "Despuesdelejerciciosedesaparece",
                                e.target.value
                              )
                            }
                          />
                          {touched.Despuesdelejerciciosedesaparece &&
                            errors.Despuesdelejerciciosedesaparece && (
                              <div>
                                {errors.Despuesdelejerciciosedesaparece}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Después del ejercicio no cambia
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Despuesdelejercicionocambia}
                            onChange={(e) =>
                              setFieldValue(
                                "Despuesdelejercicionocambia",
                                e.target.value
                              )
                            }
                          />
                          {touched.Despuesdelejercicionocambia &&
                            errors.Despuesdelejercicionocambia && (
                              <div>{errors.Despuesdelejercicionocambia}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Después del ejercicio se disminuye{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Despuesdelejerciciosedisminuye}
                            onChange={(e) =>
                              setFieldValue(
                                "Despuesdelejerciciosedisminuye",
                                e.target.value
                              )
                            }
                          />
                          {touched.Despuesdelejerciciosedisminuye &&
                            errors.Despuesdelejerciciosedisminuye && (
                              <div>{errors.Despuesdelejerciciosedisminuye}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Impresión Diagnostica
                          </label>
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue(
                                "ImpresionDiagnostica",
                                e.target.value
                              )
                            }
                          >
                            {values.ImpresionDiagnostica}
                          </textarea>
                          {touched.ImpresionDiagnostica &&
                            errors.ImpresionDiagnostica && (
                              <div>{errors.ImpresionDiagnostica}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Ausencia</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Ausencia}
                            onChange={(e) =>
                              setFieldValue("Ausencia", e.target.value)
                            }
                          />
                          {touched.Ausencia && errors.Ausencia && (
                            <div>{errors.Ausencia}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Prótesis </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Prótesis}
                            onChange={(e) =>
                              setFieldValue("Prótesis", e.target.value)
                            }
                          />
                          {touched.Prótesis && errors.Prótesis && (
                            <div>{errors.Prótesis}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Amalgama </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Amalgama}
                            onChange={(e) =>
                              setFieldValue("Amalgama ", e.target.value)
                            }
                          />
                          {touched.Amalgama && errors.Amalgama && (
                            <div>{errors.Amalgama}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Nombre y teléfono del odontólogo
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Nombreytelefonodelodontologo}
                            onChange={(e) =>
                              setFieldValue(
                                "Nombreytelefonodelodontologo",
                                e.target.value
                              )
                            }
                          />
                          {touched.Nombreytelefonodelodontologo &&
                            errors.Nombreytelefonodelodontologo && (
                              <div>{errors.Nombreytelefonodelodontologo}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Anormalidadensuaspecto}
                            onChange={(e) =>
                              setFieldValue(
                                "Anormalidadensuaspecto",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Anormalidad en su aspecto
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.ExisteDisnea}
                            onChange={(e) =>
                              setFieldValue("ExisteDisnea", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Existe Disnea
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.ExisteDeformidad}
                            onChange={(e) =>
                              setFieldValue("ExisteDeformidad", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Existe Deformidad
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Faltaalgunmiembroopartedeel}
                            onChange={(e) =>
                              setFieldValue(
                                "Faltaalgunmiembroopartedeel",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Falta algún miembro o parte de él
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.HayParalsiisoParesias}
                            onChange={(e) =>
                              setFieldValue(
                                "HayParalsiisoParesias",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Hay Parálsiis o Paresias
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Movimientosanormales}
                            onChange={(e) =>
                              setFieldValue(
                                "Movimientosanormales",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Movimientos anormales
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Lamanchaesnormal}
                            onChange={(e) =>
                              setFieldValue("Lamanchaesnormal", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;La mancha es normal
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Seapreciatranstornopsiquico}
                            onChange={(e) =>
                              setFieldValue(
                                "Seapreciatranstornopsiquico",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Se aprecia transtorno psiquico
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Existecualquieranormalidad}
                            onChange={(e) =>
                              setFieldValue(
                                "Existecualquieranormalidad",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Existe cualquier anormalidad
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Enestadoanatomicodeojos}
                            onChange={(e) =>
                              setFieldValue(
                                "Enestadoanatomicodeojos",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En estado anatómico de ojos
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Envisiondeojos}
                            onChange={(e) =>
                              setFieldValue("Envisiondeojos", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En visión de ojos
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Enaudiciondecadaoido}
                            onChange={(e) =>
                              setFieldValue(
                                "Enaudiciondecadaoido",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En audición de cada oido
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Encavidadbucalylafaringe}
                            onChange={(e) =>
                              setFieldValue(
                                "Encavidadbucalylafaringe",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En cavidad bucal y la faringe
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Enelcuello}
                            onChange={(e) =>
                              setFieldValue("Enelcuello", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En el cuello - Ganglios - tiroides -
                            Turgencia - e{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Entorax}
                            onChange={(e) =>
                              setFieldValue("Entorax", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En torax - inspección - auscultación{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Encolumnavertebral}
                            onChange={(e) =>
                              setFieldValue(
                                "Encolumnavertebral",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En columna vertebral - Deforidad - dolor
                            - etc
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Enabdomen}
                            onChange={(e) =>
                              setFieldValue("Enabdomen", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En abdomen - inspección - palpación -
                            etc{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Enlasextremidades}
                            onChange={(e) =>
                              setFieldValue("Enlasextremidades", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;En las extremidades - varices - ulceras
                            - etc{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Existealgundatoquesenalequefume}
                            onChange={(e) =>
                              setFieldValue(
                                "Existealgundatoquesenalequefume",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;¿Existe algún dato que señale que fume?{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={
                              values.Conoceenelexaminadoalgunfactorenhabitos
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Conoceenelexaminadoalgunfactorenhabitos",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp; ¿Conoce en el examinado algún factor en
                            hábitos?{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.ConsideraelestadodesaludcomoNormal}
                            onChange={(e) =>
                              setFieldValue(
                                "ConsideraelestadodesaludcomoNormal",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp; Considera el estado de salud como
                            Normal{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.ConsideraelestadodesaludcomoDudoso}
                            onChange={(e) =>
                              setFieldValue(
                                "ConsideraelestadodesaludcomoDudoso",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp; Considera el estado de salud como
                            Dudoso
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.ConsideraelestadodesaludcomoAnormal}
                            onChange={(e) =>
                              setFieldValue(
                                "ConsideraelestadodesaludcomoAnormal",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp; Considera el estado de salud como
                            Anormal{" "}
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Recogiopersonalmentemuestradeorina}
                            onChange={(e) =>
                              setFieldValue(
                                "Recogiopersonalmentemuestradeorina",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp; ¿Recogió personalmente muestra de
                            orina?{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Datos Adicionales
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.DatosAdicionales}
                            onChange={(e) =>
                              setFieldValue("DatosAdicionales", e.target.value)
                            }
                          />
                          {touched.DatosAdicionales &&
                            errors.DatosAdicionales && (
                              <div>{errors.DatosAdicionales}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Nombre del Médico
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NombredelMedico}
                            onChange={(e) =>
                              setFieldValue("NombredelMedico", e.target.value)
                            }
                          />
                          {touched.NombredelMedico &&
                            errors.NombredelMedico && (
                              <div>{errors.NombredelMedico}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Domicilio del Médico
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.DomiciliodelMedico}
                            onChange={(e) =>
                              setFieldValue(
                                "DomiciliodelMedico",
                                e.target.value
                              )
                            }
                          />
                          {touched.DomiciliodelMedico &&
                            errors.DomiciliodelMedico && (
                              <div>{errors.DomiciliodelMedico}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Teléfono del Médico
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.TelefonodelMedico}
                            onChange={(e) =>
                              setFieldValue("TelefonodelMedico", e.target.value)
                            }
                          />
                          {touched.TelefonodelMedico &&
                            errors.TelefonodelMedico && (
                              <div>{errors.TelefonodelMedico}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Ciudad</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Ciudad}
                            onChange={(e) =>
                              setFieldValue("Ciudad", e.target.value)
                            }
                          />
                          {touched.Ciudad && errors.Ciudad && (
                            <div>{errors.Ciudad}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Fecha</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Fecha}
                            onChange={(e) =>
                              setFieldValue("Fecha", e.target.value)
                            }
                          />
                          {touched.Fecha && errors.Fecha && (
                            <div>{errors.Fecha}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Solicitante se identificó con la credencial
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              values.Solicitanteseidentificoconlacredencial
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "Solicitanteseidentificoconlacredencial",
                                e.target.value
                              )
                            }
                          />
                          {touched.Solicitanteseidentificoconlacredencial &&
                            errors.Solicitanteseidentificoconlacredencial && (
                              <div>
                                {errors.Solicitanteseidentificoconlacredencial}
                              </div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.SolicitanteautorizaIMacasaproductora}
                            onChange={(e) =>
                              setFieldValue(
                                "SolicitanteautorizaIMacasaproductora",
                                e.target.value
                              )
                            }
                          />
                          <label className="form-label">
                            ¿Solicitante autoriza IM a casa productora?
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Expedido por</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Expedidopor}
                            onChange={(e) =>
                              setFieldValue("Expedidopor", e.target.value)
                            }
                          />
                          {touched.Expedidopor && errors.Expedidopor && (
                            <div>{errors.Expedidopor}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Número</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Numero}
                            onChange={(e) =>
                              setFieldValue("Numero", e.target.value)
                            }
                          />
                          {touched.Numero && errors.Numero && (
                            <div>{errors.Numero}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">Vigencia</label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Vigencia}
                            onChange={(e) =>
                              setFieldValue("Vigencia", e.target.value)
                            }
                          />
                          {touched.Vigencia && errors.Vigencia && (
                            <div>{errors.Vigencia}</div>
                          )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Clave y Nombre del Agente
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.ClaveyNombredelAgente}
                            onChange={(e) =>
                              setFieldValue(
                                "ClaveyNombredelAgente",
                                e.target.value
                              )
                            }
                          />
                          {touched.ClaveyNombredelAgente &&
                            errors.ClaveyNombredelAgente && (
                              <div>{errors.ClaveyNombredelAgente}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Miconsultorio}
                            onChange={(e) =>
                              setFieldValue("Miconsultorio", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Mi consultorio
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Sudomicilo}
                            onChange={(e) =>
                              setFieldValue("Sudomicilo", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Su domicilo
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <input
                            type="checkbox"
                            value={values.Otrolugar}
                            onChange={(e) =>
                              setFieldValue("Otrolugar", e.target.value)
                            }
                          />
                          <label className="form-label">
                            &nbsp;&nbsp;Otro lugar
                          </label>
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Especificar otro lugar
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.Especificarotrolugar}
                            onChange={(e) =>
                              setFieldValue(
                                "Especificarotrolugar",
                                e.target.value
                              )
                            }
                          />
                          {touched.Especificarotrolugar &&
                            errors.Especificarotrolugar && (
                              <div>{errors.Especificarotrolugar}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-md-8 col-lg-8 col-xl-12">
                    <div className="card mb-3 mt-4 p-4 rounded">
                      <div className="row">
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Nombre Completo del Examinador
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.NombreCompletodelExaminador}
                            onChange={(e) =>
                              setFieldValue(
                                "NombreCompletodelExaminador",
                                e.target.value
                              )
                            }
                          />
                          {touched.NombreCompletodelExaminador &&
                            errors.NombreCompletodelExaminador && (
                              <div>{errors.NombreCompletodelExaminador}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Teléfono del Examinador
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.TelefonodelExaminador}
                            onChange={(e) =>
                              setFieldValue(
                                "TelefonodelExaminador",
                                e.target.value
                              )
                            }
                          />
                          {touched.TelefonodelExaminador &&
                            errors.TelefonodelExaminador && (
                              <div>{errors.TelefonodelExaminador}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            Domicilio del Examinador
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.DomiciliodelExaminador}
                            onChange={(e) =>
                              setFieldValue(
                                "DomiciliodelExaminador",
                                e.target.value
                              )
                            }
                          />
                          {touched.DomiciliodelExaminador &&
                            errors.DomiciliodelExaminador && (
                              <div>{errors.DomiciliodelExaminador}</div>
                            )}
                        </div>
                        <div className="form-outline col-lg-4">
                          <label className="form-label">
                            CedulaProfesionaldelExaminador
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={values.CedulaProfesionaldelExaminador}
                            onChange={(e) =>
                              setFieldValue(
                                "CedulaProfesionaldelExaminador",
                                e.target.value
                              )
                            }
                          />
                          {touched.CedulaProfesionaldelExaminador &&
                            errors.CedulaProfesionaldelExaminador && (
                              <div>{errors.CedulaProfesionaldelExaminador}</div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center text-center text-lg-start mt-4 pt-2 pb-5">
                <Detector
                  render={({ online }) =>
                    online ? (
                      <button
                        onClick={() => {
                          handleSubmit();
                          settipo(0);
                        }}
                        type="button"
                        className="btn btn-success"
                      >
                        Guardar localmente
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          handleSubmit();
                          settipo(1);
                        }}
                        type="button"
                        className="btn btn-success"
                      >
                        Enviar informacion almacenada
                      </button>
                    )
                  }
                />
              </div>
            </section>
          </form>
        )}
      </Formik>
    </>
  );
}
