import { useState } from "react";
import Select from "react-select";
import { opciones } from "../../utils/initialParams";
const styleTitle = {
  color: '#596875'
};
export default function Form_11() {
  const [values, setvalues] = useState({
    Havariadosupesoenelultimoano: "",
    Kgsaumentados: "",
    Kgsdisminuidos: "",
    Causa: "",
    Realizaregularmentealgunaactividadfisica: "",
    Tipodeejercicio: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Hábitos Alimenticios y Deportes</h4>
                    <hr></hr> 
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Ha variado su peso en el último año?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Havariadosupesoenelultimoano"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Havariadosupesoenelultimoano: e
                    });
                  }}
                  value={values.Havariadosupesoenelultimoano}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Kgs aumentados</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Kgsaumentados}
                  name="Kgsaumentados"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Kgsaumentados: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Kgs disminuidos</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Kgsdisminuidos}
                  name="Kgsdisminuidos"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Kgsdisminuidos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Causa</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Causa}
                  name="Causa"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Causa: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  ¿Realiza regularmente alguna actividad física?
                </label>
                <Select
                  placeholder="Seleccione una opción"
                  options={opciones}
                  name="Realizaregularmentealgunaactividadfisica"
                  onChange={(e) => {
                    setvalues({
                      ...values,
                      Realizaregularmentealgunaactividadfisica: e
                    });
                  }}
                  value={values.Realizaregularmentealgunaactividadfisica}
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Tipo de ejercicio</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.Tipodeejercicio}
                  name="Tipodeejercicio"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      Tipodeejercicio: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
