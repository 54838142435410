import React from "react";

export default function ButtonSubmit({ internetValidato }) {
  return (
    <div className="d-flex justify-content-center text-center text-lg-start mt-4 pt-2">
      {!internetValidato ? (
        <button type="submit" className="btn btn-success">
          Guardar localmente
        </button>
      ) : (
        <button type="submit" className="btn btn-success">
          Enviar informacion
        </button>
      )}
    </div>
  );
}
