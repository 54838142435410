import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_21() {
  const [values, setvalues] = useState({
    NombredelMedico: "",
    DomiciliodelMedico: "",
    TelefonodelMedico: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Médico que acostumbra consultar</h4>
                    <hr></hr> 
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">Nombre del Médico</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NombredelMedico}
                  name="NombredelMedico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NombredelMedico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Domicilio del Médico</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.DomiciliodelMedico}
                  name="DomiciliodelMedico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      DomiciliodelMedico: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Teléfono del Médico</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.TelefonodelMedico}
                  name="TelefonodelMedico"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      TelefonodelMedico: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
