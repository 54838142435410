const ENDPOINT = process.env.REACT_APP_URL + "api/v1/vtiger_certificado";

export async function Contacto(data) {
  return fetch(`${ENDPOINT}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
