import { useState } from "react";
const styleTitle = {
  color: '#596875'
};
export default function Form_24() {
  const [values, setvalues] = useState({
    NombreCompletodelExaminador: "",
    TelefonodelExaminador: "",
    DomiciliodelExaminador: "",
    CedulaProfesionaldelExaminador: "",
  });
  return (
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-8 col-lg-8 col-xl-12">
          <div className="card mb-3 mt-4 p-4 rounded">
          <h4 style={styleTitle}>Examinador</h4>
                    <hr></hr>    
            <div className="row">
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  Nombre Completo del Examinador
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.NombreCompletodelExaminador}
                  name="NombreCompletodelExaminador"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      NombreCompletodelExaminador: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Teléfono del Examinador</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.TelefonodelExaminador}
                  name="TelefonodelExaminador"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      TelefonodelExaminador: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">Domicilio del Examinador</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.DomiciliodelExaminador}
                  name="DomiciliodelExaminador"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      DomiciliodelExaminador: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-outline col-lg-4">
                <label className="form-label">
                  CedulaProfesionaldelExaminador
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={values.CedulaProfesionaldelExaminador}
                  name="CedulaProfesionaldelExaminador"
                  onChange={(e) =>
                    setvalues({
                      ...values,
                      CedulaProfesionaldelExaminador: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
